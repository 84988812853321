/* Gogo Main Style 

Table of Contents

00.Variables and Imports
01.Base
02.Buttons
03.Widths-Spacing
04.Borders
05.Badges
06.Breadcrumb
07.Tabs
08.React Siema Carousel
09.Navbar
10.Menu
11.Apps
12.Chat App
13.Survey App
14.Todo App
15.Main
16.Cards
17.Dashboard
18.Calendar
19.Datatable
20.Alerts
21.Forms
22.Slider(Range)
23.Navs
24.Tooltip and popover
25.Progress
26.Rating
27.Sortable
28.Spinner
29.Croppper
30.Modal
31.Authorization
32.Html Editors
33.Icons
34.Floating Label
35.Loading
36.Context Menu
*/
/* 00.Variables and Imports */
@import url("https://fonts.googleapis.com/css?family=Nunito:300,400,400i,600,700");
/* 01.Base */
html {
  width: 100%;
  height: 100%;
  background: #f8f8f8; }

:root {
  --theme-color-1: #576a3d;
  --theme-color-2: #dd9c02;
  --theme-color-3: #849b65;
  --theme-color-4: #494d43;
  --theme-color-5: #d1c19a;
  --theme-color-6: #7c8174;
  --theme-color-1-10: rgba(87, 106, 61, 0.1);
  --theme-color-2-10: rgba(221, 156, 2, 0.1);
  --theme-color-3-10: rgba(132, 155, 101, 0.1);
  --theme-color-4-10: rgba(73, 77, 67, 0.1);
  --theme-color-5-10: rgba(209, 193, 154, 0.1);
  --theme-color-6-10: rgba(124, 129, 116, 0.1);
  --primary-color: #212121;
  --foreground-color: white;
  --separator-color: #d7d7d7; }

#root {
  height: 100%; }

body {
  font-family: "Nunito", sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
  color: #212121;
  background: #f8f8f8; }
  body.background {
    height: 100%; }
    body.background main {
      margin: 0 !important;
      height: 100%; }
      body.background main .container {
        height: 100%; }

.fixed-background {
  background: url("/assets/img/balloon.jpg") no-repeat center center fixed;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

h1 {
  font-size: 1.75rem;
  padding-bottom: 10px;
  display: inline-block; }
  @media (max-width: 767px) {
    h1 {
      font-size: 1.3rem;
      padding-top: 0.5rem; } }

h2 {
  font-size: 1.4rem; }
  @media (max-width: 767px) {
    h2 {
      font-size: 1.1rem; } }

h3 {
  font-size: 1.3rem; }
  @media (max-width: 767px) {
    h3 {
      font-size: 1rem; } }

h3 {
  font-size: 1.2rem; }
  @media (max-width: 767px) {
    h3 {
      font-size: 1rem; } }

h4 {
  font-size: 1.15rem; }
  @media (max-width: 767px) {
    h4 {
      font-size: 0.9rem; } }

h5 {
  font-size: 1.1rem; }
  @media (max-width: 767px) {
    h5 {
      font-size: 0.9rem; } }

h6 {
  font-size: 1rem; }
  @media (max-width: 767px) {
    h6 {
      font-size: 0.85rem; } }

hr {
  border-top: 1px solid #d7d7d7; }

.disable-text-selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

::selection {
  background: #b5c3a3; }

::-moz-selection {
  background: #b5c3a3; }

.scrollbar-container {
  margin-right: -15px;
  padding-right: 15px;
  position: relative; }

.ps__rail-y {
  width: 5px; }

.ps__thumb-y {
  left: 0; }

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  width: 5px;
  left: 0; }

.ps__thumb-y {
  background-color: #d7d7d7; }

.video-play-icon {
  width: 100%;
  height: 100%;
  position: absolute; }
  .video-play-icon span {
    position: absolute;
    background: rgba(255, 255, 255, 0.7);
    height: 1.25em;
    border-radius: 0.75em;
    line-height: 0.65em;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.75em;
    width: 2em;
    text-align: center; }
    .video-play-icon span:before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 0 8px 12px;
      font-size: 0;
      border-color: transparent transparent transparent #576a3d; }
  .video-play-icon:hover span, .video-play-icon:active span {
    background: rgba(255, 255, 255, 0.85); }

.logo-single {
  width: 110px;
  height: 35px;
  background: url("/assets/img/logo-black.svg") no-repeat;
  background-position: center center;
  display: inline-block;
  margin-bottom: 60px; }

.list-item-heading {
  font-size: 1rem; }

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.no-transition {
  transition: none !important; }

@media (max-width: 767px) {
  .display-1 {
    font-size: 2.8rem; } }

@media (max-width: 767px) {
  .display-2 {
    font-size: 2.6rem; } }

@media (max-width: 767px) {
  .display-3 {
    font-size: 2.4rem; } }

@media (max-width: 767px) {
  .display-4 {
    font-size: 2.2rem; } }

.lead {
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 2rem; }
  @media (max-width: 767px) {
    .lead {
      font-size: 1.1rem;
      line-height: 1.6rem; } }

a {
  color: #212121;
  transition: color 200ms; }
  a:hover, a:active {
    text-decoration: initial;
    color: #576a3d; }

p {
  font-size: 0.85rem;
  line-height: 1.3rem;
  font-family: "Nunito", sans-serif; }

.text-large {
  font-size: 1.9rem !important; }

.text-one {
  font-size: 1rem !important; }

.text-xlarge {
  font-size: 2.7rem !important; }

.text-small {
  font-size: 0.76rem;
  line-height: 0.9rem; }

.text-small-ar {
  font-size: 0.9rem;
  line-height: 0.9rem; }

.text-white {
  color: #fff !important; }

.text-extra-small {
  font-size: 0.6rem; }

.text-default {
  color: #212121 !important; }

.text-muted {
  color: #909090 !important; }

.text-semi-muted {
  color: #8f8f8f !important; }

.font-weight-medium {
  font-weight: 500; }

.font-weight-semibold {
  font-weight: 600; }

.color-theme-1 {
  color: #576a3d; }

.color-theme-2 {
  color: #dd9c02; }

.view-icon {
  font-size: 20px;
  color: #8f8f8f; }
  .view-icon:hover {
    color: #576a3d; }
  .view-icon.s {
    font-size: 18px; }

#displayOptions a {
  cursor: pointer; }

#displayOptions a.active i {
  color: #576a3d; }

#displayOptions button {
  border-color: #8f8f8f;
  color: #8f8f8f; }
  #displayOptions button:hover {
    background-color: #576a3d;
    border-color: #576a3d;
    color: #fff; }

#displayOptions .btn-outline-dark:not(:disabled):not(.disabled):active,
#displayOptions .btn-outline-dark:not(:disabled):not(.disabled).active,
#displayOptions .show > .btn-outline-dark.dropdown-toggle {
  background-color: #576a3d;
  border-color: #576a3d;
  color: #fff; }

#displayOptions .view-icon svg {
  width: 19px; }

#displayOptions .view-icon .view-icon-svg {
  fill: #8f8f8f; }

#displayOptions .view-icon:hover .view-icon-svg, #displayOptions .view-icon.active .view-icon-svg {
  fill: #576a3d; }

.text-theme-2 {
  color: #dd9c02 !important; }

.text-theme-3 {
  color: #849b65 !important; }

.text-primary,
.text-theme-1 {
  color: #576a3d !important; }

.text-secondary {
  color: #212121 !important; }

.main-heading {
  border-bottom: 1px solid #d7d7d7; }

.separator {
  border-bottom: 1px solid #d7d7d7; }

.alert-dismissible .close {
  padding: 0.5rem 1.25rem; }

.rounded {
  border-radius: 50px !important; }

.img-thumbnail {
  border-radius: 0.1rem;
  padding: 0;
  border: initial; }

.list-thumbnail {
  border-radius: 0.1rem;
  padding: 0;
  border: initial;
  height: auto;
  max-width: unset;
  height: 85px;
  object-fit: cover;
  width: unset !important; }
  @media (max-width: 991px) {
    .list-thumbnail {
      height: 80px; } }
  @media (max-width: 575px) {
    .list-thumbnail {
      height: 70px; } }
  @media (max-width: 991px) {
    .list-thumbnail.responsive {
      width: unset;
      height: 136px; } }
  @media (max-width: 575px) {
    .list-thumbnail.responsive {
      width: 110px !important;
      height: 100%; } }
  @media (max-width: 419px) {
    .list-thumbnail.responsive {
      width: 90px !important;
      height: 100%; } }
  .list-thumbnail.small {
    height: 60px;
    font-size: 1rem; }
    @media (max-width: 991px) {
      .list-thumbnail.small {
        height: 55px; } }
    @media (max-width: 575px) {
      .list-thumbnail.small {
        height: 50px; } }
  .list-thumbnail.xsmall {
    height: 40px;
    font-size: 1rem; }
    @media (max-width: 991px) {
      .list-thumbnail.xsmall {
        height: 40px; } }
    @media (max-width: 575px) {
      .list-thumbnail.xsmall {
        height: 40px; } }

.list-thumbnail-letters {
  width: 85px;
  height: 85px;
  background: #576a3d;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 1.25rem;
  color: #fff; }
  @media (max-width: 991px) {
    .list-thumbnail-letters {
      width: 80px;
      height: 80px; } }
  @media (max-width: 575px) {
    .list-thumbnail-letters {
      width: 70px;
      height: 70px; } }
  .list-thumbnail-letters.small {
    width: 60px;
    height: 60px;
    font-size: 1rem; }
    @media (max-width: 991px) {
      .list-thumbnail-letters.small {
        width: 55px;
        height: 55px; } }
    @media (max-width: 575px) {
      .list-thumbnail-letters.small {
        width: 50px;
        height: 50px; } }

.white {
  color: #fff !important; }

/* 02.Buttons */
button {
  color: #212121;
  outline: initial !important; }

.btn-arrow {
  display: inline-block;
  text-align: center;
  border-radius: 30px !important;
  width: 42px;
  height: 42px;
  line-height: 24px; }

.btn-arrow i {
  font-size: 15px;
  display: inline-block;
  text-align: center; }

.btn-sm.btn-arrow {
  width: 34px;
  height: 34px;
  line-height: 17px; }

.btn-sm.btn-arrow i {
  font-size: 13px;
  line-height: 10px; }

.btn {
  border-radius: 50px;
  outline: initial !important;
  box-shadow: none !important;
  box-shadow: initial !important;
  font-size: 0.8rem;
  padding: 0.75rem 1.3rem 0.6rem 1.3rem;
  transition: background-color box-shadow 0.1s linear; }

.btn-shadow {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 1px 3px 1px rgba(0, 0, 0, 0.15) !important;
  transition: background-color box-shadow 0.1s linear; }
  .btn-shadow:hover, .btn-shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15), 0 4px 6px 2px rgba(0, 0, 0, 0.15) !important; }

.btn-empty {
  background: transparent !important; }

.btn-lg,
.btn-group-lg > .btn,
.btn-group-sm > .btn {
  border-radius: 50px; }

.btn.default {
  border-radius: 0.1rem; }

.btn-primary {
  background-color: #576a3d;
  border-color: #576a3d;
  color: #fff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #42502e;
    border-color: #42502e; }

.check-button {
  cursor: default !important; }

.check-button.btn-primary {
  background-color: #576a3d !important;
  border-color: #576a3d !important;
  opacity: 1; }

.check-button .custom-control {
  min-height: 1.1rem;
  margin-top: -7px; }

.dropdown-menu {
  font-size: 0.8rem;
  border-radius: 0.1rem;
  background: white;
  border-color: rgba(33, 33, 33, 0.15); }

.dropdown-item {
  padding: 0.75rem 1.5rem;
  color: #212121; }

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: #f8f8f8;
  color: #212121; }

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #576a3d; }

.dropdown-divider {
  border-color: #d7d7d7; }

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-color: #374327;
  border-color: #374327;
  color: #fff; }

.btn-secondary {
  background-color: #dd9c02;
  border-color: #dd9c02;
  color: #fff; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #b57f02;
    border-color: #b57f02; }

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  background-color: #a07101;
  border-color: #a07101;
  color: #fff; }

.btn-primary.btn-primary-gradient {
  background: linear-gradient(to right, #576a3d, #2f3921); }
  .btn-primary.btn-primary-gradient:hover {
    background: linear-gradient(to right, #576a3d, #42502e); }

.btn-primary-gradient:not(:disabled):not(.disabled):active,
.btn-primary-gradient:not(:disabled):not(.disabled).active,
.show > .btn-primary-gradient.dropdown-toggle {
  background: linear-gradient(to right, #576a3d, #2f3921); }

.btn-secondary-gradient {
  background: linear-gradient(to right, #dd9c02, #916601); }
  .btn-secondary-gradient:hover {
    background: linear-gradient(to right, #dd9c02, #b57f02); }

.btn-secondary-gradient:not(:disabled):not(.disabled):active,
.btn-secondary-gradient:not(:disabled):not(.disabled).active,
.show > .btn-secondary-gradient.dropdown-toggle {
  background: linear-gradient(to right, #dd9c02, #916601); }

.btn-warning {
  background-color: #b69329;
  border-color: #b69329; }

.btn-success,
.btn-info,
.btn-danger,
.btn-warning {
  color: #fff; }
  .btn-success:hover,
  .btn-info:hover,
  .btn-danger:hover,
  .btn-warning:hover {
    color: #fff; }

.btn-outline-success:hover,
.btn-outline-info:hover,
.btn-outline-danger:hover,
.btn-outline-warning:hover {
  color: white; }

.btn-light {
  color: #131113;
  background-color: #ececec;
  border-color: #ececec; }
  .btn-light:hover {
    color: #131113;
    background-color: #d8d8d8;
    border-color: #d8d8d8; }

.btn-dark {
  color: #ececec;
  background-color: #131113;
  border-color: #131113; }
  .btn-dark:hover {
    color: #ececec;
    background-color: #060506;
    border-color: #060506; }

.btn-outline-dark {
  color: #131113;
  border-color: #131113; }
  .btn-outline-dark:hover {
    color: white;
    background-color: #131113;
    border-color: #131113; }

.btn-outline-white {
  color: #fff;
  border-color: #fff;
  background-color: initial; }
  .btn-outline-white:hover {
    color: #576a3d;
    background-color: #fff; }

.btn-outline-light {
  color: #ececec;
  border-color: #ececec; }
  .btn-outline-light:hover {
    color: white;
    background-color: #ececec;
    border-color: #ececec; }

.btn-outline-primary {
  color: #576a3d;
  border-color: #576a3d; }
  .btn-outline-primary:hover {
    color: white;
    background-color: #576a3d;
    border-color: #576a3d; }

.btn-outline-theme-3 {
  background: unset;
  color: #849b65;
  border-color: #849b65; }
  .btn-outline-theme-3:hover {
    background-color: #849b65;
    border-color: #849b65;
    color: white; }

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  background-color: #576a3d;
  border-color: #576a3d;
  color: white; }

.btn-outline-secondary {
  color: #dd9c02;
  border-color: #dd9c02; }
  .btn-outline-secondary:hover {
    background-color: #dd9c02;
    border-color: #dd9c02;
    color: white; }

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  background-color: #dd9c02;
  border-color: #dd9c02;
  color: white; }

.btn-header-light {
  color: #d7d7d7;
  border-color: transparent;
  background: transparent; }
  .btn-header-light:hover {
    background-color: transparent;
    border-color: #d7d7d7; }

.btn-header-primary {
  color: #576a3d;
  border-color: transparent;
  background: transparent; }
  .btn-header-primary:hover {
    background-color: transparent;
    border-color: #576a3d; }

.btn-header-secondary {
  color: #dd9c02;
  border-color: transparent;
  background: transparent; }
  .btn-header-secondary:hover {
    background-color: transparent;
    border-color: #dd9c02; }

.btn-header-primary-light {
  color: #849b65;
  border-color: transparent;
  background: transparent; }
  .btn-header-primary-light:hover {
    background-color: transparent;
    border-color: #849b65; }

.btn-xl,
.btn-group-xl > .btn {
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: 0.05rem;
  padding: 1rem 3.5rem 0.9rem; }

.btn-lg,
.btn-group-lg > .btn {
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: 0.05rem;
  padding: 0.75rem 2.6rem 0.6rem 2.6rem; }

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.5rem 1rem;
  font-size: 0.76rem;
  line-height: 1.5; }

.btn-xs,
.btn-group-xs > .btn {
  padding: 0.25rem 0.75rem 0.25rem 0.75rem;
  font-size: 0.76rem;
  line-height: 1.3; }

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #576a3d;
  border-color: #576a3d;
  color: white; }

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: #dd9c02;
  border-color: #dd9c02;
  color: white; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.btn-link {
  color: #576a3d; }

.btn-link:hover {
  color: rgba(87, 106, 61, 0.8);
  text-decoration: underline; }

.white-underline-link {
  color: #fff;
  text-decoration: underline; }
  .white-underline-link:hover, .white-underline-link:active {
    color: #fff;
    text-decoration: initial; }

.btn-multiple-state {
  position: relative;
  transition: opacity 500ms; }
  .btn-multiple-state .spinner,
  .btn-multiple-state .icon {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    opacity: 0;
    visibility: hidden;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 500ms;
    color: #fff; }
  .btn-multiple-state .icon i {
    vertical-align: text-bottom;
    font-size: 18px; }
  .btn-multiple-state .label {
    transition: opacity 500ms; }
  .btn-multiple-state.show-spinner .label {
    opacity: 0; }
  .btn-multiple-state.show-spinner .spinner {
    opacity: 1;
    visibility: visible; }
  .btn-multiple-state.show-success .label {
    opacity: 0; }
  .btn-multiple-state.show-success .icon.success {
    opacity: 1;
    visibility: visible; }
  .btn-multiple-state.show-fail .label {
    opacity: 0; }
  .btn-multiple-state.show-fail .icon.fail {
    opacity: 1;
    visibility: visible; }
  .btn-multiple-state.btn-primary:disabled {
    opacity: 1;
    background: #3c4a2a;
    border-color: #3c4a2a; }
  .btn-multiple-state.btn-secondary:disabled {
    opacity: 1;
    border-color: #aa7802; }

.icon-button {
  padding: 0;
  font-size: 14px;
  width: 34px;
  height: 34px;
  line-height: 34px; }
  .icon-button.large {
    width: 44px;
    height: 44px;
    font-size: 18px; }
  .icon-button.small-icon {
    font-size: 12px;
    line-height: 32px; }

.top-right-button {
  width: calc(100% - 80px); }
  @media (max-width: 575px) {
    .top-right-button {
      padding-left: 0;
      padding-right: 0; } }

.top-right-button-single {
  width: unset; }
  @media (max-width: 991px) {
    .top-right-button-single {
      width: calc(100%); } }

/* 03.Widths-Spacing */
.w-10 {
  width: 10% !important; }

.w-90 {
  width: 90% !important; }

.w-12 {
  width: 12% !important; }

.w-88 {
  width: 88% !important; }

.w-15 {
  width: 15% !important; }

.w-85 {
  width: 85% !important; }

.w-20 {
  width: 20% !important; }

.w-80 {
  width: 80% !important; }

.w-30 {
  width: 30% !important; }

.w-70 {
  width: 70% !important; }

.w-40 {
  width: 40% !important; }

.w-60 {
  width: 60% !important; }

@media (max-width: 767px) {
  .w-xs-100 {
    width: 100% !important; } }

@media (max-width: 991px) {
  .w-sm-100 {
    width: 100% !important; } }

.r-0 {
  right: 0; }

.l-0 {
  left: 0; }

.depth-1 {
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04); }

.depth-2 {
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1); }

.min-width-zero {
  min-width: 0; }

.mb-5,
.my-5 {
  margin-bottom: 2rem !important; }

@media (max-width: 575px) {
  .float-none-xs {
    float: initial !important; } }

/* 04.Borders */
.border {
  border: 1px solid #f3f3f3 !important; }

.border-right {
  border-right: 1px solid #f3f3f3 !important; }

.border-left {
  border-left: 1px solid #f3f3f3 !important; }

.border-top {
  border-top: 1px solid #f3f3f3 !important; }

.border-bottom {
  border-bottom: 1px solid #f3f3f3 !important; }

.border-primary,
.border-theme-1 {
  border-color: #576a3d !important; }

.border-theme-2 {
  border-color: #dd9c02 !important; }

.border-theme-3 {
  border-color: #849b65 !important; }

.border-secondary {
  border-color: #212121 !important; }

/* 05.Badges */
.bg-theme-2,
.badge-theme-2 {
  background-color: #dd9c02 !important;
  color: #fff; }

.bg-theme-3,
.badge-theme-3 {
  background-color: #849b65 !important;
  color: #fff; }

.bg-primary,
.bg-theme-1,
.badge-primary,
.badge-theme-1 {
  background-color: #576a3d !important;
  color: #fff; }

.bg-secondary,
.badge-secondary {
  background-color: #dd9c02 !important;
  color: #fff; }

.badge-warning {
  background-color: #b69329; }

.badge-success {
  background-color: #3e884f; }

.badge-info {
  background-color: #3195a5; }

.badge-danger {
  background-color: #c43d4b; }

.badge-success,
.badge-danger,
.badge-warning,
.badge-info {
  color: #fff; }

.badge {
  padding: 0.55em 0.75em 0.6em 0.75em;
  font-size: 74%; }
  .badge.badge-pill {
    padding-right: 1.25em;
    padding-left: 1.25em; }
  .badge.badge-top-left {
    top: 10px;
    left: -7px; }
  .badge.badge-top-left-2 {
    top: 40px;
    left: -7px; }
  .badge.badge-top-right {
    top: 8px;
    right: -7px; }
  .badge.badge-top-right-2 {
    top: 40px;
    right: -7px; }

.badge-light {
  background-color: #ececec;
  color: #131113; }

.badge-dark {
  background-color: #131113;
  color: #ececec; }

.badge-outline-primary,
.badge-outline-theme-1 {
  background: unset;
  border: 1px solid #576a3d;
  color: #576a3d; }

.badge-outline-secondary,
.badge-outline-theme-2 {
  background: unset;
  border: 1px solid #dd9c02;
  color: #dd9c02; }

.badge-outline-theme-3 {
  background: unset;
  border: 1px solid #849b65;
  color: #849b65; }

.badge-outline-success {
  background: unset;
  border: 1px solid #3e884f;
  color: #3e884f; }

.badge-outline-danger {
  background: unset;
  border: 1px solid #c43d4b;
  color: #c43d4b; }

.badge-outline-warning {
  background: unset;
  border: 1px solid #b69329;
  color: #b69329; }

.badge-outline-info {
  background: unset;
  border: 1px solid #3195a5;
  color: #3195a5; }

.badge-outline-light {
  background: unset;
  border: 1px solid #ececec;
  color: #ececec; }

.badge-outline-dark {
  background: unset;
  border: 1px solid #131113;
  color: #131113; }

/* 06.Breadcrumb */
@media (max-width: 991px) {
  .breadcrumb-container .breadcrumb {
    padding: 0; } }

.breadcrumb {
  background-color: transparent;
  margin-bottom: 0.5rem; }

.breadcrumb-item + .breadcrumb-item::before {
  content: "|"; }

/* 07.Tabs */
.nav-tabs.separator-tabs {
  border-bottom: 1px solid #d7d7d7; }

.nav-tabs .nav-link {
  border: initial;
  padding-top: 1rem; }

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background: white; }

.nav-tabs .nav-link.active::before,
.nav-tabs .nav-item.show .nav-link::before {
  content: " ";
  background: #576a3d;
  border-radius: 10px;
  position: absolute;
  width: 100%;
  height: 5px;
  left: 0;
  top: 0; }

.nav-tabs.separator-tabs .nav-link.active::before,
.nav-tabs.separator-tabs .nav-item.show .nav-link::before {
  content: " ";
  background: #576a3d;
  border-radius: 10px;
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  bottom: 0;
  top: unset; }

.nav-tabs.separator-tabs .nav-link {
  border: initial;
  padding-top: 1rem;
  background: initial;
  padding-left: 0;
  padding-top: 0.5rem;
  padding-right: 0;
  margin-right: 1.5rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #8f8f8f; }
  .nav-tabs.separator-tabs .nav-link span {
    cursor: pointer; }

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border: initial;
  position: relative;
  color: #576a3d; }

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border: initial;
  color: #576a3d; }

.react-siema-container {
  overflow: hidden;
  padding-bottom: 15px;
  padding-top: 5px; }

.slider-nav .left-arrow,
.slider-nav .right-arrow {
  font-size: 20px;
  color: #576a3d;
  display: inline-block;
  vertical-align: middle;
  margin: 0 15px;
  padding-top: 14px; }

.slider-nav .slider-dot-container {
  display: inline-block; }

.slider-nav .btn:hover, .slider-nav .btn:focus, .slider-nav .btn:active {
  text-decoration: initial; }

.slider-dot {
  width: 6px;
  height: 6px;
  border-radius: 10px;
  background: #d7d7d7;
  outline: initial !important;
  border: initial;
  margin: 0 5px;
  padding: 0;
  cursor: pointer; }
  .slider-dot.active {
    background: #576a3d; }

.react-siema-container .card .card-body {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.react-siema-container .card .w-50 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

/* 09.Navbar*/
.navbar {
  background: white;
  height: 80px;
  padding: 1.5rem 60px 1.5rem 0;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04); }
  .navbar #notificationDropdown {
    width: 260px;
    padding: 1.5rem;
    height: 280px;
    right: 15px; }
  .navbar #iconMenuDropdown {
    width: 240px;
    padding: 1.5rem;
    height: 280px; }
  .navbar .icon-menu-item {
    width: 90px;
    display: inline-block;
    text-align: center;
    margin-bottom: 1.7rem;
    color: rgba(33, 33, 33, 0.8); }
    .navbar .icon-menu-item i {
      font-size: 28px;
      line-height: 42px; }
    .navbar .icon-menu-item span {
      text-align: center;
      padding: 0 10px;
      line-height: 14px; }
    .navbar .icon-menu-item:hover, .navbar .icon-menu-item:focus {
      color: #576a3d; }
  .navbar .menu-button-mobile {
    color: #8f8f8f;
    text-align: center; }
    .navbar .menu-button-mobile svg {
      height: 12px;
      fill: #212121; }
    @media (max-width: 767px) {
      .navbar .menu-button-mobile {
        width: 20px; } }
  .navbar .menu-button {
    color: #8f8f8f;
    width: 120px;
    text-align: center; }
    .navbar .menu-button svg {
      height: 12px; }
    .navbar .menu-button .main {
      fill: #212121;
      transition: fill 300ms; }
    .navbar .menu-button .sub {
      fill: #212121;
      transition: fill 300ms; }
    .navbar .menu-button:hover {
      color: #576a3d; }
    @media (max-width: 767px) {
      .navbar .menu-button {
        width: 60px; } }
  .navbar .navbar-logo {
    left: 20px;
    right: 0;
    margin: 0 auto;
    text-align: center;
    font-size: 1.2rem; }
    .navbar .navbar-logo .logo {
      width: 100%;
      height: 100%;
      background: url("/assets/img/logo-black.svg") no-repeat;
      background-position: center center; }
    .navbar .navbar-logo .logo-mobile {
      width: 100%;
      height: 100%;
      background: url("/assets/img/logo-mobile.svg") no-repeat;
      background-position: center center; }
    @media (max-width: 767px) {
      .navbar .navbar-logo {
        position: absolute;
        width: calc(100% - 270px);
        font-size: 1rem; } }
  .navbar .language-button {
    background: #f8f8f8;
    border: initial;
    font-size: 0.8rem;
    color: #8f8f8f;
    padding: 0.6rem 1rem; }
    @media (max-width: 575px) {
      .navbar .language-button {
        padding-left: 0.75rem;
        padding-right: 0.75rem; } }
  .navbar .search {
    position: relative;
    width: 230px;
    border-radius: 20px;
    background: #f8f8f8; }
    .navbar .search > div > div {
      padding: 0 !important; }
    .navbar .search input {
      border: initial;
      background: transparent;
      outline: initial !important;
      padding: 0.5rem 1rem;
      line-height: 2;
      font-size: 0.8rem;
      width: 93%;
      color: #212121; }
    .navbar .search .search-icon {
      font-size: 17px;
      border-radius: 10px;
      color: #8f8f8f;
      position: absolute;
      width: 40px;
      height: 40px;
      bottom: -8px;
      right: 3px;
      text-align: center;
      cursor: pointer; }
      .navbar .search .search-icon:hover {
        color: #576a3d; }
    @media (max-width: 991px) {
      .navbar .search {
        width: 115px; }
        .navbar .search input {
          width: 85%; } }
    @media (max-width: 767px) {
      .navbar .search {
        width: 30px;
        height: 30px;
        background: initial;
        margin-left: 0.6rem;
        color: rgba(33, 33, 33, 0.7); }
        .navbar .search input {
          display: none; }
        .navbar .search .search-icon {
          font-size: 17px;
          width: 30px;
          height: 30px;
          bottom: -3px;
          right: 0;
          color: inherit; }
        .navbar .search.mobile-view {
          display: block;
          width: 100%;
          position: fixed;
          z-index: 2;
          background: white;
          left: 0;
          top: 0;
          height: 70px;
          margin-left: 15px; }
          .navbar .search.mobile-view input {
            display: block;
            width: 100%;
            height: 70px;
            padding-left: 0; }
          .navbar .search.mobile-view span {
            top: 50%;
            transform: translateY(-50%);
            right: 25px; } }
  .navbar .header-icons {
    margin-right: 1rem; }
    @media (max-width: 575px) {
      .navbar .header-icons {
        margin-right: 0; } }
  .navbar .header-icon {
    font-size: 16px;
    color: #8f8f8f;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    vertical-align: initial; }
    @media (max-width: 575px) {
      .navbar .header-icon {
        padding-left: 0.3rem;
        padding-right: 0.3rem; } }
    .navbar .header-icon:hover {
      color: #576a3d; }
    .navbar .header-icon#fullScreenButton i:last-of-type {
      display: none; }
    .navbar .header-icon.notificationButton .count {
      font-size: 9px;
      color: #576a3d;
      border: 1px solid #576a3d;
      border-radius: 10px;
      position: absolute;
      width: 18px;
      height: 15px;
      text-align: center;
      font-weight: 700;
      top: 2px;
      right: 2px;
      line-height: 14px; }
      @media (max-width: 575px) {
        .navbar .header-icon.notificationButton .count {
          right: -1px; } }
  .navbar .user {
    color: #6e6e6e;
    position: relative; }
    .navbar .user img {
      margin-left: 10px;
      border-radius: 30px;
      width: 40px; }
    @media (max-width: 767px) {
      .navbar .user {
        margin-left: initial; }
        .navbar .user img {
          width: 30px; }
        .navbar .user:after {
          font-size: 11px;
          width: 14px;
          height: 14px;
          bottom: -3px;
          right: -3px; } }
  @media (max-width: 1439px) {
    .navbar {
      height: 80px; } }
  @media (max-width: 1199px) {
    .navbar {
      height: 80px;
      padding: 1.5rem 40px 1.5rem 0; } }
  @media (max-width: 767px) {
    .navbar {
      height: 70px;
      padding: 10px 15px; } }

#app-container.sub-hidden .menu-button .sub,
#app-container.menu-sub-hidden .menu-button .sub {
  fill: #8f8f8f; }

#app-container.main-hidden .menu-button .main,
#app-container.main-hidden .menu-button .sub,
#app-container.menu-hidden .menu-button .main,
#app-container.menu-hidden .menu-button .sub {
  fill: #8f8f8f; }

/* 10.Menu*/
#app-container .sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  padding-top: 80px;
  z-index: 1000;
  height: calc(100% - 80px); }
  #app-container .sidebar .scrollbar-container {
    margin-right: 0;
    padding-right: 0; }
  #app-container .sidebar .main-menu {
    width: 120px;
    height: calc(100% - 80px);
    background: white;
    z-index: 900;
    position: fixed;
    transition: transform 300ms;
    padding-top: 2px;
    left: 0; }
    #app-container .sidebar .main-menu .scroll {
      padding-right: unset;
      margin-right: unset;
      height: 100%; }
      #app-container .sidebar .main-menu .scroll .ps__thumb-y {
        right: 0; }
    #app-container .sidebar .main-menu.main-hidden {
      transform: translateX(-120px); }
    #app-container .sidebar .main-menu ul li {
      position: relative; }
      #app-container .sidebar .main-menu ul li span {
        text-align: center;
        padding: 0 10px;
        line-height: 14px; }
      #app-container .sidebar .main-menu ul li a {
        height: 110px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        border-bottom: 1px solid #f3f3f3;
        color: #212121;
        transition: color 300ms;
        transition: background 300ms; }
        #app-container .sidebar .main-menu ul li a:hover, #app-container .sidebar .main-menu ul li a:focus {
          color: #576a3d;
          background: #f8f8f8; }
      #app-container .sidebar .main-menu ul li i {
        font-size: 32px;
        line-height: 42px; }
      #app-container .sidebar .main-menu ul li.active a {
        color: #576a3d; }
      #app-container .sidebar .main-menu ul li.active:after {
        content: " ";
        background: #576a3d;
        border-radius: 10px;
        position: absolute;
        width: 6px;
        height: 90px;
        top: 50%;
        transform: translateY(-50%);
        left: 0; }
    @media (max-width: 1439px) {
      #app-container .sidebar .main-menu {
        width: 110px;
        height: calc(100% - 80px); } }
    @media (max-width: 1199px) {
      #app-container .sidebar .main-menu {
        width: 100px;
        height: calc(100% - 80px); } }
    @media (max-width: 767px) {
      #app-container .sidebar .main-menu {
        width: 90px;
        height: calc(100% - 70px); }
        #app-container .sidebar .main-menu ul li i {
          font-size: 28px;
          line-height: 38px; }
        #app-container .sidebar .main-menu ul li a {
          height: 90px; }
        #app-container .sidebar .main-menu ul li.active:after {
          width: 3px;
          height: 60px; } }
  #app-container .sidebar .sub-menu {
    background: white;
    z-index: 800;
    position: fixed;
    left: 120px;
    border-left: 1px solid #f3f3f3;
    box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1);
    transition: transform 300ms;
    height: calc(100% - 80px); }
    #app-container .sidebar .sub-menu .nav {
      width: 230px; }
    #app-container .sidebar .sub-menu .scroll {
      height: 100%;
      margin-top: 2px;
      margin-right: unset;
      padding-right: unset; }
      #app-container .sidebar .sub-menu .scroll .ps__thumb-y {
        right: 0; }
    #app-container .sidebar .sub-menu .ps {
      padding-top: 25px;
      padding-bottom: 25px; }
    #app-container .sidebar .sub-menu ul {
      display: none; }
      #app-container .sidebar .sub-menu ul li {
        margin-bottom: 10px;
        margin-left: 30px; }
        #app-container .sidebar .sub-menu ul li a {
          font-size: 13px;
          display: block;
          padding: 8px 0; }
        #app-container .sidebar .sub-menu ul li i {
          font-size: 1.3em;
          margin-right: 10px;
          color: #8f8f8f;
          vertical-align: middle; }
        #app-container .sidebar .sub-menu ul li span {
          vertical-align: middle;
          padding-top: 3px;
          display: inline-block; }
        #app-container .sidebar .sub-menu ul li.active i,
        #app-container .sidebar .sub-menu ul li.active a {
          color: #576a3d; }
        @media (max-width: 767px) {
          #app-container .sidebar .sub-menu ul li {
            margin-left: 15px; } }
    @media (max-width: 1439px) {
      #app-container .sidebar .sub-menu {
        left: 110px;
        height: calc(100% - 80px); }
        #app-container .sidebar .sub-menu .nav {
          width: 220px; } }
    @media (max-width: 1199px) {
      #app-container .sidebar .sub-menu {
        left: 100px;
        height: calc(100% - 80px); }
        #app-container .sidebar .sub-menu .nav {
          width: 200px; } }
    @media (max-width: 767px) {
      #app-container .sidebar .sub-menu {
        left: 90px;
        height: calc(100% - 70px); }
        #app-container .sidebar .sub-menu .nav {
          width: 190px; } }
  @media (max-width: 1439px) {
    #app-container .sidebar {
      padding-top: 80px; } }
  @media (max-width: 1199px) {
    #app-container .sidebar {
      padding-top: 80px; } }
  @media (max-width: 767px) {
    #app-container .sidebar {
      padding-top: 70px;
      box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04); } }

#app-container.sub-hidden .sub-menu,
#app-container.menu-sub-hidden .sub-menu,
#app-container.menu-hidden .sub-menu {
  transform: translateX(-230px); }
  @media (max-width: 1439px) {
    #app-container.sub-hidden .sub-menu,
    #app-container.menu-sub-hidden .sub-menu,
    #app-container.menu-hidden .sub-menu {
      transform: translateX(-220px); } }
  @media (max-width: 1199px) {
    #app-container.sub-hidden .sub-menu,
    #app-container.menu-sub-hidden .sub-menu,
    #app-container.menu-hidden .sub-menu {
      transform: translateX(-200px); } }
  @media (max-width: 767px) {
    #app-container.sub-hidden .sub-menu,
    #app-container.menu-sub-hidden .sub-menu,
    #app-container.menu-hidden .sub-menu {
      transform: translateX(-190px); } }

#app-container.main-hidden .main-menu,
#app-container.menu-hidden .main-menu {
  transform: translateX(-120px); }
  @media (max-width: 1439px) {
    #app-container.main-hidden .main-menu,
    #app-container.menu-hidden .main-menu {
      transform: translateX(-110px); } }
  @media (max-width: 1199px) {
    #app-container.main-hidden .main-menu,
    #app-container.menu-hidden .main-menu {
      transform: translateX(-100px); } }
  @media (max-width: 767px) {
    #app-container.main-hidden .main-menu,
    #app-container.menu-hidden .main-menu {
      transform: translateX(-90px); } }

#app-container.main-hidden.sub-hidden .sub-menu,
#app-container.menu-hidden .sub-menu {
  transform: translateX(-350px); }
  @media (max-width: 1439px) {
    #app-container.main-hidden.sub-hidden .sub-menu,
    #app-container.menu-hidden .sub-menu {
      transform: translateX(-330px); } }
  @media (max-width: 1199px) {
    #app-container.main-hidden.sub-hidden .sub-menu,
    #app-container.menu-hidden .sub-menu {
      transform: translateX(-300px); } }
  @media (max-width: 767px) {
    #app-container.main-hidden.sub-hidden .sub-menu,
    #app-container.menu-hidden .sub-menu {
      transform: translateX(-280px); } }

#app-container.menu-mobile .main-menu {
  transform: translateX(-90px); }

#app-container.menu-mobile .sub-menu {
  transform: translateX(-330px); }

#app-container.main-show-temporary .main-menu {
  transform: translateX(0); }

#app-container.main-show-temporary .sub-menu {
  transform: translateX(-230px); }

@media (max-width: 1439px) {
  #app-container.main-show-temporary .main-menu {
    transform: translateX(0); }
  #app-container.main-show-temporary .sub-menu {
    transform: translateX(-220px); } }

@media (max-width: 1199px) {
  #app-container.main-show-temporary .main-menu {
    transform: translateX(0); }
  #app-container.main-show-temporary .sub-menu {
    transform: translateX(-200px); } }

@media (max-width: 767px) {
  #app-container.main-show-temporary .sub-menu {
    transform: translateX(-190px); } }

#app-container.sub-show-temporary .sub-menu, #app-container.menu-mobile.sub-show-temporary .sub-menu {
  transform: translateX(0); }

/* 11.Apps*/
.app-menu {
  z-index: 1;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  width: 280px;
  float: right;
  background: white;
  transition: transform 300ms;
  height: calc(100% - 80px);
  position: fixed;
  right: 0;
  top: 80px; }
  @media (max-width: 1439px) {
    .app-menu {
      top: 80px;
      height: calc(100% - 80px); } }
  @media (max-width: 1199px) {
    .app-menu {
      top: 80px;
      transform: translateX(280px);
      height: calc(100% - 80px); } }
  @media (max-width: 767px) {
    .app-menu {
      top: 70px;
      height: calc(100% - 70px); } }
  .app-menu .scrollbar-container {
    margin-right: unset;
    padding-right: unset; }
    .app-menu .scrollbar-container .ps__rail-y {
      right: 2px !important; }
  .app-menu.shown {
    transform: translateX(0); }
  .app-menu .app-menu-button {
    cursor: pointer;
    position: absolute;
    left: -28px;
    background: white;
    top: 45px;
    padding: 12px 8px 12px 5px;
    border-radius: 0.2rem;
    color: #8f8f8f !important;
    box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.04);
    font-size: 15px;
    line-height: 0; }
    @media (max-width: 1439px) {
      .app-menu .app-menu-button {
        top: 36px; } }
    @media (max-width: 1199px) {
      .app-menu .app-menu-button {
        top: 27px; } }
    @media (max-width: 767px) {
      .app-menu .app-menu-button {
        top: 13px; } }
  .app-menu ul li {
    margin-bottom: 5px; }
    .app-menu ul li a {
      font-size: 13px;
      display: block;
      padding: 3px 0; }
      .app-menu ul li a:hover i {
        color: #576a3d; }
    .app-menu ul li i {
      font-size: 1.2em;
      margin-right: 10px;
      color: #8f8f8f;
      vertical-align: unset; }
      @media (max-width: 767px) {
        .app-menu ul li i {
          font-size: 20px; } }
    .app-menu ul li.active i,
    .app-menu ul li.active a {
      color: #576a3d; }

.app-row {
  padding-right: 280px; }
  @media (max-width: 1199px) {
    .app-row {
      padding-right: 0; } }

/* 12.Chat App*/
.chat-app .scrollbar-container {
  padding-left: 10px;
  margin-left: -10px;
  padding-right: 10px;
  margin-right: 0;
  height: calc(100vh - 80px - 270px); }
  @media (max-width: 1439px) {
    .chat-app .scrollbar-container {
      height: calc(100vh - 80px - 270px); } }
  @media (max-width: 1199px) {
    .chat-app .scrollbar-container {
      height: calc(100vh - 80px - 270px); } }
  @media (max-width: 767px) {
    .chat-app .scrollbar-container {
      height: calc(100vh - 70px - 220px); } }
  .chat-app .scrollbar-container .ps__thumb-y {
    right: 10px; }

.chat-app .chat-text-left {
  padding-left: 64px; }

.chat-app .chat-text-right {
  padding-right: 64px; }

@media (max-width: 767px) {
  .chat-app .list-item-heading {
    font-size: 0.9rem; } }

@media (max-width: 767px) {
  .chat-app .card .card-body {
    padding: 0.75rem; } }

.chat-app-tab-content {
  height: calc(100% - 45px); }

.chat-app-tab-pane {
  height: calc(100% - 45px); }

.chat-input-container {
  width: 100%;
  height: 90px;
  background: white;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  padding-left: 410px;
  padding-right: 340px; }
  @media (max-width: 1439px) {
    .chat-input-container {
      padding-left: 160px;
      padding-right: 330px; } }
  @media (max-width: 1199px) {
    .chat-input-container {
      padding-left: 140px;
      padding-right: 40px; } }
  @media (max-width: 767px) {
    .chat-input-container {
      padding-right: 15px;
      padding-left: 15px; } }
  .chat-input-container input {
    border: initial;
    width: unset;
    height: 90px;
    padding-left: 0;
    display: inline-block; }
  @media (max-width: 991px) {
    .chat-input-container .send-button {
      padding-left: 0.7rem;
      padding-right: 0.7rem; } }

/* 13.Survey App*/
.survey-app .answers .badge {
  color: #8f8f8f;
  font-size: 13px;
  padding-left: 0.75em;
  padding-right: 0.75em; }

.survey-app .answers input {
  padding-right: 70px; }

.survey-app .question.edit-quesiton .edit-mode {
  display: block; }

.survey-app .question.edit-quesiton .view-mode {
  display: none; }

.survey-app .question.edit-quesiton .view-button {
  display: inline-block; }

.survey-app .question.edit-quesiton .edit-button {
  display: none; }

.survey-app .question.view-quesiton .edit-mode {
  display: none; }

.survey-app .question.view-quesiton .view-mode {
  display: block; }

.survey-app .question.view-quesiton .view-button {
  display: none; }

.survey-app .question.view-quesiton .edit-button {
  display: inline-block; }

.survey-app .survey-question-types .btn-group-icon {
  font-size: 21px;
  line-height: 28px; }

.survey-app .survey-question-types .btn {
  padding-bottom: 0.3rem; }

.input-icons {
  position: absolute;
  right: 0;
  top: 9px; }

/* 14.Todo App*/
.heading-icon {
  font-size: 20px;
  color: #576a3d;
  margin-right: 5px;
  line-height: inherit;
  vertical-align: middle; }

.todo-details {
  margin-top: 20px;
  margin-left: 40px; }

.heading-number {
  border: 1px solid #576a3d;
  padding: 4px;
  vertical-align: middle;
  margin-right: 10px;
  border-radius: 20px;
  width: 34px;
  height: 34px;
  text-align: center;
  color: #576a3d; }

/* 15.Main*/
main {
  margin-left: 410px;
  margin-top: 125px;
  margin-right: 60px;
  margin-bottom: 40px;
  transition: margin-left 300ms; }
  main.sub-hidden {
    margin-left: 180px; }
  main.main-hidden {
    margin-left: 60px; }
  main .container-fluid {
    padding-left: 0;
    padding-right: 0; }
  @media (max-width: 1439px) {
    main {
      margin-left: 380px;
      margin-right: 50px;
      margin-top: 120px;
      margin-bottom: 30px; } }
  @media (max-width: 1199px) {
    main {
      margin-left: 340px;
      margin-right: 40px;
      margin-top: 110px;
      margin-bottom: 20px; } }
  @media (max-width: 767px) {
    main {
      margin-left: 15px !important;
      margin-right: 15px !important;
      margin-top: 85px !important;
      margin-bottom: 0; } }
  @media (max-width: 575px) {
    main {
      margin-bottom: 0; } }

#app-container.sub-hidden main,
#app-container.menu-sub-hidden main,
#app-container.menu-hidden main {
  margin-left: 180px; }

#app-container.main-hidden main,
#app-container.menu-hidden main {
  margin-left: 60px; }

@media (max-width: 1439px) {
  #app-container.sub-hidden main,
  #app-container.menu-sub-hidden main,
  #app-container.menu-hidden main {
    margin-left: 160px; }
  #app-container.main-hidden main,
  #app-container.menu-hidden main {
    margin-left: 50px; } }

@media (max-width: 1199px) {
  #app-container.sub-hidden main,
  #app-container.menu-sub-hidden main,
  #app-container.menu-hidden main {
    margin-left: 140px; }
  #app-container.main-hidden main,
  #app-container.menu-hidden main {
    margin-left: 40px; } }

/* 16.Cards*/
.card {
  border: initial;
  background: white;
  border-radius: calc(0.15rem - 1px);
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04); }
  .card .card-header .card-icon {
    right: 5px;
    top: 5px; }
    .card .card-header .card-icon i {
      font-size: 12px;
      color: #d7d7d7; }
  .card .card-subtitle {
    margin: 0;
    margin-bottom: 1rem; }
  .card .card-header .handle {
    cursor: default; }
  .card .card-body {
    padding: 1.75rem; }
    @media (max-width: 575px) {
      .card .card-body {
        padding: 1.25rem; } }
    .card .card-body.sm {
      padding: 1.25rem 1.75rem; }
  .card .card-title {
    margin-bottom: 2rem; }
    @media (max-width: 767px) {
      .card .card-title {
        margin-bottom: 1.25rem; } }

.card-img {
  border-radius: calc(0.25rem - 1px);
  height: 100%;
  object-fit: cover;
  max-height: 200px;
  width: unset; }

.card-img-fluid {
  border-radius: calc(0.25rem - 1px);
  object-fit: cover; }

.card-img-bottom {
  width: 100%;
  border-bottom-left-radius: calc(0.15rem - 1px);
  border-bottom-right-radius: calc(0.15rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.15rem - 1px);
  border-top-right-radius: calc(0.15rem - 1px); }

.card-img-left {
  width: 100%;
  border-top-left-radius: calc(0.15rem - 1px);
  border-bottom-left-radius: calc(0.15rem - 1px);
  height: 100%;
  object-fit: cover; }

.card-img-right {
  width: 100%;
  border-top-right-radius: calc(0.15rem - 1px);
  border-bottom-right-radius: calc(0.15rem - 1px);
  height: 100%;
  object-fit: cover; }

.card-img-overlay {
  background: rgba(0, 0, 0, 0.5);
  padding: 1.75rem; }
  @media (max-width: 575px) {
    .card-img-overlay {
      padding: 1rem; } }

.card-top-buttons {
  padding: 1.3rem;
  right: 0;
  top: 0; }
  @media (max-width: 575px) {
    .card-top-buttons {
      padding: 0.35rem; } }

.card-header {
  border: initial;
  background: initial;
  padding-top: 0; }

/* 17.Dashboard*/
.dashboard-line-chart {
  height: 283px; }

.dashboard-quick-post {
  min-height: 263px; }

.dashboard-list-with-thumbs {
  height: 500px; }

.dashboard-logs {
  height: 270px; }

.dashboard-list-with-user {
  height: 270px; }

.ems-documents-list {
  height: 800px; }

.dashboard-donut-chart {
  height: 270px; }

.dashboard-small-chart {
  height: 150px; }
  .dashboard-small-chart .chart {
    height: 75px; }
  .dashboard-small-chart .lead {
    font-size: 1.4rem; }

.dashboard-small-chart-analytics {
  height: 180px; }
  .dashboard-small-chart-analytics .chart {
    height: 85px; }
  .dashboard-small-chart-analytics .lead {
    font-size: 1.4rem; }

.dashboard-filled-line-chart {
  height: 340px; }
  .dashboard-filled-line-chart .chart {
    height: 200px; }

.dashboard-sq-banner {
  background: #ececec;
  transition: 0.5s;
  border: 1px solid lightgray;
  color: #6d6d6d;
  cursor: pointer; }
  .dashboard-sq-banner .lead {
    line-height: 2.3rem; }

.dashboard-link-list {
  height: 385px; }

.dashboard-progress {
  height: 385px; }

.dashboard-top-rated {
  height: 300px; }
  @media (max-width: 991px) {
    .dashboard-top-rated {
      height: unset; } }
  .dashboard-top-rated .react-siema-container {
    margin-right: -0.5rem;
    margin-left: -0.5rem; }
    .dashboard-top-rated .react-siema-container img {
      height: 120px;
      display: flex;
      object-fit: cover;
      width: 100%; }
    .dashboard-top-rated .react-siema-container .react-rater {
      display: inline-block !important; }

.dashboard-search {
  height: 650px;
  background: url(/assets/img/plane.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-color: white; }
  .dashboard-search .card-body {
    padding: 120px 50px 30px 50px; }
    @media (max-width: 1439px) {
      .dashboard-search .card-body {
        padding: 80px 30px 30px 30px; } }
  .dashboard-search .form-container {
    height: 400px;
    border-radius: 0.1rem;
    box-shadow: 0px -10px 15px 0px rgba(0, 0, 0, 0.04);
    padding: 2rem;
    background-color: white; }

.icon-cards-row {
  margin-left: -5px;
  margin-right: -5px;
  margin-top: -10px; }
  .icon-cards-row .react-siema-container {
    padding-bottom: 0px;
    padding-top: 10px; }
    .icon-cards-row .react-siema-container .icon-row-item {
      padding-left: 5px;
      padding-right: 5px; }
  .icon-cards-row [class*="col-"] {
    padding-left: 5px;
    padding-right: 5px; }
  .icon-cards-row .card-body {
    padding: 2rem 0.5rem; }
  .icon-cards-row .card-text {
    color: #8f8f8f;
    height: 30px;
    line-height: 26px; }
  .icon-cards-row .lead {
    color: #576a3d;
    margin-bottom: 0; }
  .icon-cards-row i {
    font-size: 46px;
    line-height: 66px;
    color: #576a3d; }
  .icon-cards-row .card {
    transition: box-shadow 1000ms; }
  @media (max-width: 1439px) {
    .icon-cards-row .card-text {
      height: 48px;
      line-height: 18px; }
    .icon-cards-row .lead {
      font-size: 1.6rem; }
    .icon-cards-row i {
      font-size: 32px;
      line-height: 47px; } }

.log-indicator {
  width: 13px;
  height: 13px;
  border: 2px solid #576a3d;
  border-radius: 14px;
  display: inline-block; }

/* 18.Calendar */
.big-calendar-header {
  margin-bottom: 1em; }

.rbc-month-header {
  min-height: 50px; }

.rbc-today {
  background: initial; }

.calendar-prev-btn,
.calendar-next-btn {
  outline: initial !important;
  box-shadow: initial !important;
  border-radius: 40px !important;
  text-align: center;
  min-width: 30px;
  height: 30px;
  padding: 0.55rem 0;
  background: #576a3d;
  color: white;
  border: 1px solid #576a3d;
  line-height: 0.9 !important;
  font-size: 0.76rem;
  font-weight: normal !important; }
  .calendar-prev-btn span,
  .calendar-next-btn span {
    line-height: 1 !important;
    font-size: 0.76rem;
    font-weight: normal !important; }
  .calendar-prev-btn:hover,
  .calendar-next-btn:hover {
    background-color: transparent;
    border-color: #728a50;
    color: #576a3d; }

.calendar-prev-btn {
  margin-right: 5px; }

.calendar-today-btn {
  padding: 0.4em 1.3em !important;
  height: unset !important; }

.rbc-month-row {
  min-height: 5em;
  z-index: 1; }
  @media (max-width: 575px) {
    .rbc-month-row {
      min-height: 3em; } }

.rbc-month-view {
  border: initial; }

.rbc-off-range-bg {
  background: initial; }

.rbc-off-range {
  color: #212121;
  opacity: 0.3; }

.rbc-day-bg + .rbc-day-bg,
.rbc-month-row + .rbc-month-row,
.rbc-header + .rbc-header,
.rbc-header {
  border-color: #f3f3f3 !important; }

.rbc-header {
  padding: 15px 5px;
  color: #576a3d; }

.rbc-date-cell {
  padding: 10px; }
  .rbc-date-cell a {
    border-radius: 50%;
    width: 28px;
    height: 28px;
    text-align: center;
    vertical-align: middle;
    padding: 5px;
    font-weight: initial;
    display: inline-block; }

.rbc-date-cell.rbc-now a {
  background: #576a3d;
  color: white; }

.rbc-event {
  font-size: 0.85em;
  border-radius: 25px;
  text-align: center;
  padding: 0px 5px;
  background: #dd9c02; }

.fc-basic-view .fc-body .fc-row {
  min-height: 6em; }
  @media (max-width: 575px) {
    .fc-basic-view .fc-body .fc-row {
      min-height: 3em; } }

.fc-bootstrap4 .fc-day-top .fc-day-number {
  border-radius: 50%;
  width: 18px;
  height: 18px;
  text-align: center;
  vertical-align: middle;
  padding: 5px; }

.fc-bootstrap4 td.fc-today {
  background: initial; }
  .fc-bootstrap4 td.fc-today .fc-day-number {
    background: #576a3d;
    color: #fff; }

.fc-day-grid-container {
  height: 100%;
  overflow: visible; }

.fc-row .fc-content-skeleton td,
.fc-row .fc-helper-skeleton td {
  padding: 5px; }

.fc-view,
.fc-view > table {
  border-top: initial; }

.fc table {
  border: initial; }

.fc .table-bordered thead th,
.fc .table-bordered thead td {
  border-top: initial;
  border-bottom: initial;
  border-left: initial; }

.fc td:first-of-type,
.fc th:first-of-type {
  border-left: initial;
  border-bottom: initial; }

.fc td:last-of-type,
.fc th:last-of-type {
  border-right: initial;
  border-bottom: initial; }

.fc-body .fc-row:last-of-type td {
  border-bottom: initial; }

.fc tbody > tr > td.fc-widget-content:first-of-type {
  border-left: initial;
  border-right: initial;
  border-bottom: initial; }

.fc table,
.fc .table-bordered th,
.fc .table-bordered td {
  border-color: #f3f3f3 !important; }

.fc-day-header {
  color: #576a3d; }
  .fc-day-header span {
    padding: 10px;
    display: inline-block; }

.fc-event {
  border: initial; }

.fc-event,
.fc-event-dot {
  background-color: #dd9c02;
  color: #fff !important;
  padding: 1px 6px;
  border-radius: 10px;
  cursor: pointer;
  text-align: center; }

/* 19.Datatable */
.ReactTable {
  border: initial; }

.ReactTable .rt-th,
.ReactTable .rt-td {
  padding-top: 20px;
  padding-bottom: 10px; }

.ReactTable .rt-thead.-header {
  box-shadow: initial; }

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: initial;
  border: initial;
  text-align: left;
  font-weight: 700; }

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 1px solid #f3f3f3; }

.ReactTable .list-item-heading {
  margin-bottom: 0; }

.ReactTable .rt-tbody .rt-td {
  border-right: initial; }

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 #576a3d; }

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 #576a3d; }

/* 20.Alerts */
.alert {
  border-radius: 0; }

.alert-primary {
  color: #576a3d;
  background-color: rgba(87, 106, 61, 0.2);
  border-color: rgba(87, 106, 61, 0.1); }

.alert-secondary {
  color: #dd9c02;
  background-color: rgba(221, 156, 2, 0.2);
  border-color: rgba(221, 156, 2, 0.1); }

.alert-success {
  color: #3e884f;
  background-color: rgba(62, 136, 79, 0.2);
  border-color: rgba(62, 136, 79, 0.1); }

.alert-info {
  color: #3195a5;
  background-color: rgba(49, 149, 165, 0.2);
  border-color: rgba(49, 149, 165, 0.1); }

.alert-warning {
  color: #b69329;
  background-color: rgba(182, 147, 41, 0.2);
  border-color: rgba(182, 147, 41, 0.1); }

.alert-danger {
  color: #c43d4b;
  background-color: rgba(196, 61, 75, 0.2);
  border-color: rgba(196, 61, 75, 0.1); }

.alert-light {
  color: #ececec;
  background-color: rgba(236, 236, 236, 0.2);
  border-color: rgba(236, 236, 236, 0.1); }

.alert-dark {
  color: #131113;
  background-color: rgba(19, 17, 19, 0.2);
  border-color: rgba(19, 17, 19, 0.1); }

.alert-dismissible .close {
  text-shadow: initial; }

.alert *[data-notify="title"] {
  display: block;
  font-size: 0.9rem; }

div[data-notify="container"] {
  padding: 18px; }

.notification-container {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  width: 320px;
  padding: 0px 15px;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto; }

.notification {
  box-sizing: border-box;
  padding: 15px 15px 15px 58px;
  border-radius: 2px;
  cursor: pointer;
  font-size: 1em;
  line-height: 1.2em;
  position: relative;
  opacity: 0.9;
  margin-top: 15px; }

.notification .title {
  font-size: 1em;
  line-height: 1.2em;
  font-weight: bold;
  margin: 0 0 5px 0; }

.notification:hover,
.notification:focus {
  opacity: 1; }

.notification-enter {
  visibility: hidden;
  transform: translate3d(100%, 0, 0); }

.notification-enter.notification-enter-active {
  visibility: visible;
  transform: translate3d(0, 0, 0);
  transition: all 0.4s; }

.notification-leave {
  visibility: visible;
  transform: translate3d(0, 0, 0); }

.notification-leave.notification-leave-active {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
  transition: all 0.4s; }

/* 21.Forms */
.av-invalid .av-label {
  color: #212121; }

.av-tooltip .invalid-feedback {
  border-radius: 15px;
  padding: 0.5rem 1rem;
  font-size: 0.76rem;
  color: white;
  background: #c43d4b;
  text-align: center;
  width: unset !important;
  position: absolute;
  z-index: 1;
  margin-top: -0.2rem;
  left: 50%;
  transform: translateX(-50%); }
  .av-tooltip .invalid-feedback:after {
    content: "";
    position: absolute;
    top: -5px;
    left: -2.5px;
    margin-left: 50%;
    width: 10px;
    height: 5px;
    border-bottom: solid 5px #c43d4b;
    border-left: solid 5px transparent;
    border-right: solid 5px transparent; }

@media (max-width: 575px) {
  .form-inline .form-group {
    width: 100%; } }

.form-check-label,
.custom-control-label {
  line-height: 24px; }

.react-tagsinput {
  background-color: white;
  border: 1px solid #d7d7d7;
  outline: initial;
  box-shadow: initial; }

.react-tagsinput-tag {
  background: #576a3d;
  border-radius: 15px;
  padding: 0px 10px;
  margin-bottom: 0px;
  display: inline-block;
  font-size: 12px;
  color: white;
  border: initial; }

.form-control:focus {
  background: white;
  color: #212121; }

.bootstrap-tagsinput {
  width: 100%; }

.bootstrap-tagsinput input {
  padding: 0; }

.form-control:focus {
  border-color: rgba(87, 106, 61, 0.6); }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.4rem + 3px); }

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: rgba(33, 33, 33, 0.25); }

.custom-control-input:active ~ .custom-control-label::before {
  background-color: transparent; }

.custom-checkbox .custom-control-label.indeterminate::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='#fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#fff'/%3E%3C/svg%3E"); }

.custom-control-label,
.custom-control-input {
  outline: initial !important;
  box-shadow: initial !important; }

.custom-control-input {
  left: 1px;
  top: 3px;
  opacity: 0;
  z-index: 1; }

.custom-control-label::before {
  border: 1px solid #909090;
  background: initial; }

.custom-checkbox
.custom-control-input:indeterminate
~ .custom-control-label::before {
  background-color: #576a3d; }

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #576a3d;
  box-shadow: initial !important;
  border: 1px solid #576a3d; }

.custom-checkbox
.custom-control-input:checked.custom-control-input-dark
~ .custom-control-label::before {
  background-color: #fff;
  border: 1px solid #849b65; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #576a3d;
  box-shadow: initial !important; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #576a3d;
  box-shadow: initial !important; }

.custom-control-label::after,
.custom-control-label::before {
  box-shadow: initial !important; }

.custom-control-label::before {
  top: 0.25rem; }

.custom-control-label::after {
  top: 0.25rem; }

.btn.rotate-icon-click i {
  transition: transform 0.5s; }

.btn.rotate i {
  transform: rotate(180deg); }

.btn .custom-control-label::before {
  border: 1px solid #fff; }

.btn .custom-control-input:checked ~ .custom-control-label::before {
  border: 1px solid #fff; }

.btn-group-icon {
  line-height: 22px; }

.valid-tooltip,
.invalid-tooltip {
  border-radius: 15px;
  padding: 0.5rem 1rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.76rem; }

.valid-tooltip {
  background-color: #28a745;
  margin-top: -0.2rem; }

.invalid-tooltip {
  background-color: #dc3545;
  margin-top: -0.2rem; }

.valid-tooltip:after {
  content: "";
  position: absolute;
  top: -5px;
  left: -2.5px;
  margin-left: 50%;
  width: 10px;
  height: 5px;
  border-bottom: solid 5px #28a745;
  border-left: solid 5px transparent;
  border-right: solid 5px transparent; }

.invalid-tooltip:after {
  content: "";
  position: absolute;
  top: -5px;
  left: -2.5px;
  margin-left: 50%;
  width: 10px;
  height: 5px;
  border-bottom: solid 5px #dc3545;
  border-left: solid 5px transparent;
  border-right: solid 5px transparent; }

.valid-icon {
  position: absolute;
  bottom: 8px;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0;
  font-size: 0.76rem;
  line-height: 1;
  border-radius: 0.2rem;
  right: 4px;
  color: #3e884f; }

.invalid-icon {
  position: absolute;
  bottom: 2px;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0;
  font-size: 0.875rem;
  line-height: 1;
  border-radius: 0.2rem;
  right: 4px;
  color: #c43d4b; }

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-icon,
.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-icon,
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-icon,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-icon {
  display: block; }

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-icon,
.form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-icon,
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-icon,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-icon {
  display: block; }

.autosuggest {
  position: relative; }

.react-autosuggest__suggestions-container {
  border-radius: 0.1rem;
  z-index: 20;
  box-shadow: initial;
  margin-top: -1px;
  background: white;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  top: 100%; }

.react-autosuggest__suggestions-list {
  list-style: none;
  padding: 0;
  margin-bottom: 0; }

.react-autosuggest__suggestion {
  cursor: default;
  display: block;
  font-size: inherit;
  padding: 8px 12px;
  width: 100%;
  background: white !important;
  color: #212121 !important; }
  .react-autosuggest__suggestion:hover, .react-autosuggest__suggestion:active {
    background: #728a50 !important;
    color: white !important; }

.react-autosuggest__input--open {
  border: 1px solid rgba(87, 106, 61, 0.6) !important; }

.react-autosuggest__suggestions-container--open {
  border: 1px solid rgba(87, 106, 61, 0.6);
  border-top: initial;
  border-width: 1px !important; }

.react-select {
  outline: initial !important;
  box-shadow: none !important; }

.react-select__value-container {
  outline: initial !important;
  box-shadow: none !important;
  padding: 0.45rem 0.75rem 0.4rem 0.75rem !important;
  background: white !important; }

.react-select .react-select__dropdown-indicator {
  color: #8f8f8f; }

.react-select .react-select__menu-list {
  padding-bottom: 0;
  padding-top: 0; }

.react-select .react-select__single-value,
.react-select .react-select__multi-value__label {
  color: #212121; }

.react-select .react-select__dropdown-indicator,
.react-select
.react-select__control--is-focused
.react-select__dropdown-indicator,
.react-select .react-select__clear-indicator,
.react-select
.react-select__control--is-focused
.react-select__clear-indicator {
  outline: initial;
  box-shadow: initial; }
  .react-select .react-select__dropdown-indicator:active, .react-select .react-select__dropdown-indicator:focus, .react-select .react-select__dropdown-indicator:hover,
  .react-select
.react-select__control--is-focused
.react-select__dropdown-indicator:active,
  .react-select
.react-select__control--is-focused
.react-select__dropdown-indicator:focus,
  .react-select
.react-select__control--is-focused
.react-select__dropdown-indicator:hover,
  .react-select .react-select__clear-indicator:active,
  .react-select .react-select__clear-indicator:focus,
  .react-select .react-select__clear-indicator:hover,
  .react-select
.react-select__control--is-focused
.react-select__clear-indicator:active,
  .react-select
.react-select__control--is-focused
.react-select__clear-indicator:focus,
  .react-select
.react-select__control--is-focused
.react-select__clear-indicator:hover {
    color: #576a3d !important; }

.react-select__control {
  border-radius: 0.1rem !important;
  border: 1px solid #d7d7d7 !important;
  background: white !important;
  outline: initial !important;
  box-shadow: none !important; }

.react-select__indicator-separator {
  display: none; }

.react-select__dropdown-indicator svg {
  width: 15px;
  height: 15px; }

.react-select__option {
  background: white !important;
  color: #212121 !important; }
  .react-select__option:hover, .react-select__option:active {
    background: #576a3d !important;
    color: #fff !important; }

.react-select__option--is-selected {
  background: #576a3d !important;
  color: #fff !important; }

.react-select__control--is-focused {
  border-color: rgba(87, 106, 61, 0.6) !important; }

.react-select__multi-value {
  background: transparent !important;
  border: 1px solid #d7d7d7; }

.react-select__multi-value__remove:hover,
.react-select__multi-value__remove:active {
  background: transparent !important;
  color: #576a3d !important; }

.react-select .react-select__menu {
  border-radius: 0.1rem !important;
  z-index: 20 !important;
  box-shadow: initial !important;
  border: 1px solid rgba(87, 106, 61, 0.6) !important;
  border-top: initial !important;
  margin-top: -1px !important;
  background-color: white;
  border-width: 1px !important; }

.react-select__single-value {
  bottom: 0;
  top: 50%;
  padding-top: 2px; }

.react-datepicker {
  background-color: white;
  border: #d7d7d7; }

.react-datepicker__input-container input {
  background-color: white;
  border: #d7d7d7; }

.react-datepicker__input-container input:focus {
  border-color: rgba(87, 106, 61, 0.6) !important; }

.react-datepicker-popper {
  z-index: 20; }

.react-datepicker-wrapper {
  width: 100%; }

.react-datepicker__input-container {
  width: 100%; }

.react-datepicker__input-container input {
  font-size: 0.8rem;
  padding: 0.65rem 0.75rem 0.5rem 0.75rem !important;
  height: calc(2.4rem + 3px) !important;
  border: 1px solid #d7d7d7;
  border-radius: 0.1rem;
  width: 100%;
  outline: initial; }

.react-datepicker {
  border: 1px solid #d7d7d7;
  border-radius: 0.1rem;
  font-family: "Nunito", sans-serif; }

.react-datepicker__header {
  background-color: white;
  border-bottom: initial; }

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 35px;
  height: 35px;
  line-height: 2.3rem;
  border-radius: 0 !important;
  margin: 0;
  outline: initial !important; }

.react-datepicker__day:hover {
  background: #d7d7d7; }

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__day--keyboard-selected {
  background: #576a3d; }

.react-datepicker__day--today {
  font-weight: 400;
  color: #fff !important; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  border-bottom-color: white; }

.react-datepicker-popper[data-placement^="bottom"]
.react-datepicker__triangle::before {
  border-bottom-color: #d7d7d7; }

.react-datepicker__current-month,
.react-datepicker-time__header {
  color: #212121; }

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #212121; }

.react-datepicker__input-container input {
  color: #212121; }

.react-datepicker__time-container {
  border-left: 1px solid #d7d7d7;
  width: 71px; }

.react-datepicker__time-container .react-datepicker__time {
  background-color: white;
  color: #212121; }

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  color: #fff; }

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-top-color: white; }

.react-datepicker-popper[data-placement^="top"]
.react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  border-top-color: #d7d7d7; }

.react-datepicker__time-container
.react-datepicker__time
.react-datepicker__time-box
ul.react-datepicker__time-list
li.react-datepicker__time-list-item:hover {
  background: #f8f8f8; }

.react-datepicker__triangle {
  left: 30px; }

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background: #576a3d; }

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
  background: #d7d7d7; }

.react-datepicker.embedded {
  border: initial;
  width: 100%; }
  .react-datepicker.embedded .react-datepicker__day-name,
  .react-datepicker.embedded .react-datepicker__day,
  .react-datepicker.embedded .react-datepicker__time-name {
    width: 14.28%; }
  .react-datepicker.embedded .react-datepicker__month-container {
    width: 100%; }

.react-datepicker__time-container
.react-datepicker__time
.react-datepicker__time-box {
  width: 87px; }

.react-datepicker__time-container
.react-datepicker__time
.react-datepicker__time-box
ul.react-datepicker__time-list {
  padding-left: 0;
  padding-right: 30px; }

.react-datepicker__time-container
.react-datepicker__time
.react-datepicker__time-box
ul.react-datepicker__time-list
li.react-datepicker__time-list-item--selected {
  background: #576a3d; }

.react-fine-uploader-gallery-dropzone,
.react-fine-uploader-gallery-nodrop-container {
  min-height: 118px;
  border: 1px solid #d7d7d7;
  background: white;
  padding: 10px 10px;
  border-radius: 0.1rem;
  color: #212121;
  padding: 0 !important; }

.react-fine-uploader-gallery-dropzone-active {
  border: 1px solid rgba(87, 106, 61, 0.6) !important; }

.react-fine-uploader-gallery-dropzone button svg {
  fill: #576a3d; }

.react-fine-uploader-gallery-file-input-container {
  display: inline-block;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute !important;
  margin: 0;
  padding: 0; }

.react-fine-uploader-gallery-dropzone-content,
.react-fine-uploader-gallery-nodrop-content {
  font-size: 0.8rem;
  color: #212121;
  opacity: 1;
  top: 50%;
  transform: translateY(-50%); }

.react-fine-uploader-gallery-files {
  padding: 10px;
  margin-bottom: 0; }

.react-fine-uploader-gallery-file {
  display: flex;
  flex-direction: row;
  width: 260px;
  height: unset;
  box-shadow: initial !important;
  border-radius: 0.1rem;
  font-size: 0.8rem;
  border: 1px solid #d7d7d7;
  float: left;
  margin: 16px; }

.react-fine-uploader-gallery-thumbnail-container {
  display: block;
  height: unset !important;
  text-align: center;
  width: 30% !important;
  height: 62px !important;
  overflow: hidden; }

.react-fine-uploader-gallery-progress-bar-container {
  width: 100%;
  height: 3px;
  background: #d7d7d7; }

.react-fine-uploader-gallery-progress-bar,
.react-fine-uploader-gallery-total-progress-bar {
  background: #576a3d;
  box-shadow: initial; }

.react-fine-uploader-gallery-status,
.react-fine-uploader-filesize-value,
.react-fine-uploader-filesize-unit {
  font-style: initial;
  color: #576a3d;
  font-size: 0.76rem; }

.react-fine-uploader-filename.react-fine-uploader-gallery-filename {
  font-weight: normal; }

.react-fine-uploader-gallery-total-progress-bar-container {
  display: none;
  background: #d7d7d7; }

.react-fine-uploader-gallery-thumbnail {
  height: 100%; }

.react-fine-uploader-gallery-upload-failed-icon,
.react-fine-uploader-gallery-upload-success-icon {
  height: 36px;
  width: 36px; }

.react-fine-uploader-gallery-upload-failed-icon {
  fill: #fff;
  opacity: 1; }

.react-fine-uploader-gallery-file-footer {
  width: 70%;
  padding: 15px;
  background: white; }

.react-fine-uploader-gallery-thumbnail-icon-backdrop {
  opacity: 0; }

.react-fine-uploader-gallery-thumbnail-icon-backdrop,
.react-fine-uploader-gallery-upload-failed-icon,
.react-fine-uploader-gallery-upload-success-icon {
  left: 38px;
  position: absolute;
  top: 50%; }

.dropzone .dz-message {
  text-align: center; }

.dropzone.dz-clickable {
  cursor: pointer; }

.dropzone.dz-clickable .dz-message,
.dropzone.dz-clickable .dz-message * {
  position: relative;
  transform: translateY(-50%);
  top: 50%;
  margin: 0; }

.dropzone .dz-preview.dz-image-preview {
  width: 260px;
  height: unset;
  min-height: unset;
  border: 1px solid #d7d7d7;
  border-radius: 0.1rem;
  background: white;
  color: #212121; }
  .dropzone .dz-preview.dz-image-preview strong {
    font-weight: normal; }
  .dropzone .dz-preview.dz-image-preview .remove {
    position: absolute;
    right: 5px;
    top: 5px;
    color: #576a3d; }
  .dropzone .dz-preview.dz-image-preview .dz-details {
    position: static;
    display: block;
    opacity: 1;
    text-align: left;
    min-width: unset;
    z-index: initial;
    color: #212121; }
  .dropzone .dz-preview.dz-image-preview .dz-error-mark {
    color: #fff;
    transform: translateX(-50%) translateY(-50%) !important;
    transition: initial !important;
    animation: initial !important;
    margin-left: 0;
    margin-top: 0; }
    .dropzone .dz-preview.dz-image-preview .dz-error-mark i {
      font-size: 26px; }
  .dropzone .dz-preview.dz-image-preview .dz-progress {
    width: 90%;
    margin-left: 0;
    margin-top: 0;
    left: 50%;
    right: 0;
    transform: translateX(-50%);
    height: 5px; }
    .dropzone .dz-preview.dz-image-preview .dz-progress .dz-upload {
      width: 100%;
      background: #576a3d; }
  .dropzone .dz-preview.dz-image-preview .dz-error-message {
    border-radius: 15px;
    background: #c43d4b;
    top: 60px; }
    .dropzone .dz-preview.dz-image-preview .dz-error-message:after {
      border-bottom: 6px solid #c43d4b; }

.custom-switch {
  display: block;
  background: #d7d7d7;
  width: 58px;
  height: 27px;
  border: 1px solid #d7d7d7; }
  .custom-switch:after {
    width: 18px;
    height: 18px;
    top: 3px;
    margin-left: 3px;
    box-shadow: initial;
    transform: initial !important;
    animation-name: unset !important;
    background: white; }
  .custom-switch.rc-switch-checked:after {
    left: 30px; }
  .custom-switch.rc-switch-checked.custom-switch-primary {
    background: #576a3d;
    border: 1px solid #576a3d; }
  .custom-switch.rc-switch-checked.custom-switch-secondary {
    background: #dd9c02;
    border: 1px solid #dd9c02; }
  .custom-switch.custom-switch-primary-inverse {
    border: 1px solid #d7d7d7; }
  .custom-switch.rc-switch-checked.custom-switch-primary-inverse {
    background: white;
    border: 1px solid #576a3d; }
    .custom-switch.rc-switch-checked.custom-switch-primary-inverse:after {
      background: #576a3d; }
  .custom-switch.custom-switch-secondary-inverse {
    border: 1px solid #d7d7d7; }
  .custom-switch.rc-switch-checked.custom-switch-secondary-inverse {
    background: white;
    border: 1px solid #dd9c02; }
    .custom-switch.rc-switch-checked.custom-switch-secondary-inverse:after {
      background: #dd9c02; }

.custom-switch .custom-switch-input + .custom-switch-btn {
  background: #d7d7d7; }

.custom-switch.custom-switch-primary
.custom-switch-input:checked
+ .custom-switch-btn {
  background: #576a3d;
  border: 1px solid #576a3d; }

.custom-switch.custom-switch-secondary
.custom-switch-input:checked
+ .custom-switch-btn {
  background: #dd9c02;
  border: 1px solid #dd9c02; }

.custom-switch.custom-switch-primary-inverse
.custom-switch-input
+ .custom-switch-btn {
  border: 1px solid #d7d7d7; }

.custom-switch.custom-switch-primary-inverse
.custom-switch-input:checked
+ .custom-switch-btn {
  background: white;
  border: 1px solid #576a3d; }

.custom-switch.custom-switch-primary-inverse
.custom-switch-input:checked
+ .custom-switch-btn:after {
  background: #576a3d; }

.custom-switch.custom-switch-secondary-inverse
.custom-switch-input
+ .custom-switch-btn {
  border: 1px solid #d7d7d7; }

.custom-switch.custom-switch-secondary-inverse
.custom-switch-input:checked
+ .custom-switch-btn {
  background: white;
  border: 1px solid #dd9c02; }

.custom-switch.custom-switch-secondary-inverse
.custom-switch-input:checked
+ .custom-switch-btn:after {
  background: #dd9c02; }

.custom-switch .custom-switch-input + .custom-switch-btn:after {
  background: white; }

.custom-switch .custom-switch-input + .custom-switch-btn {
  border-color: #d7d7d7; }

.input-group-text {
  border-radius: 0.1rem;
  background-color: white;
  border-color: #d7d7d7;
  color: #212121;
  font-size: 0.8rem;
  padding: 0.75rem 0.75rem 0.5rem 0.75rem; }

.form-control {
  border-radius: 0.1rem;
  outline: initial !important;
  box-shadow: initial !important;
  font-size: 0.8rem;
  padding: 0.7rem 0.75rem 0.65rem 0.75rem;
  line-height: 1.5;
  border: 1px solid #d7d7d7;
  background: white;
  color: #212121; }

@keyframes autofill {
  to {
    color: #212121;
    background: transparent; } }

input:-webkit-autofill {
  animation-name: autofill;
  animation-fill-mode: both; }

input:-webkit-autofill {
  -webkit-text-fill-color: #212121 !important; }

.form-control-sm,
.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  border-radius: 0.1rem; }

.form-control-lg,
.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  border-radius: 0.1rem; }

.custom-select {
  border-radius: 0.1rem;
  padding: 0.75rem 0.75rem 0.5rem 0.75rem; }

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  outline: initial !important;
  box-shadow: initial !important; }

.custom-select {
  height: calc(2.5rem + 2px); }

.custom-select:focus {
  border-color: #576a3d; }

.custom-file-input:focus ~ .custom-file-label {
  border-color: rgba(87, 106, 61, 0.6); }

.custom-file-label::after {
  background: white;
  color: #212121;
  border-color: #d7d7d7; }

.custom-file-input {
  box-shadow: initial !important; }

.custom-file-label {
  background: white;
  border-color: #d7d7d7; }

.custom-file-label {
  box-shadow: initial !important;
  border-radius: 0.1rem;
  height: calc(2.5rem + 2px);
  padding: 0.75rem 0.75rem 0.5rem 0.75rem; }

.custom-file {
  height: calc(2.5rem + 2px); }

.custom-file-label:focus,
.custom-file-input:focus {
  border-color: #576a3d; }

.custom-file-label::after {
  height: calc(calc(2.5rem + 2px) - 1px * 2);
  padding: 0.75rem 0.75rem 0.5rem 0.75rem; }

.jumbotron {
  background: initial; }

/* 22.Slider(Range) */
.rc-slider-tooltip {
  background: white;
  color: #212121;
  border: 1px solid #d7d7d7;
  border-radius: 0.1rem;
  text-align: center;
  top: 150%;
  bottom: unset;
  padding: 5px;
  transform: translateX(-50%); }

.rc-slider-handle {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background: white;
  cursor: default;
  border: 1px solid #d7d7d7 !important;
  box-shadow: initial !important; }

.rc-slider-track,
.rc-slider-rail,
.rc-slider-step {
  height: 7px; }

.rc-slider-rail {
  border: 1px solid #d7d7d7;
  background: white; }

.rc-slider-handle {
  margin-top: -7px; }

.rc-slider-track {
  background: #576a3d; }

/* 23.Navs */
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: white;
  background-color: #576a3d; }

.nav-pills .nav-link {
  border-radius: 50px; }

.nav-link {
  padding: 0.5rem 1rem; }
  @media (max-width: 767px) {
    .nav-link {
      padding: 0.5rem 0.5rem; } }
  .nav-link.active {
    color: #576a3d; }

.page-item {
  padding: 10px; }
  @media (max-width: 767px) {
    .page-item {
      padding: 3px; } }
  .page-item .page-link {
    outline: initial !important;
    box-shadow: initial !important;
    line-height: 1.7;
    border-radius: 40px !important;
    min-width: 38px;
    text-align: center;
    height: 38px;
    padding: 0.55rem 0; }
    @media (max-width: 575px) {
      .page-item .page-link {
        min-width: 30px;
        height: 30px;
        line-height: 0.9;
        font-size: 0.76rem; } }
    .page-item .page-link.next {
      background: #576a3d;
      color: white;
      border: 1px solid #576a3d; }
    .page-item .page-link.prev, .page-item .page-link.prev {
      background: #576a3d;
      border: 1px solid #576a3d;
      color: white; }
    .page-item .page-link.first, .page-item .page-link.last {
      background: transparent;
      color: #576a3d;
      border: 1px solid #576a3d;
      border-radius: 30px; }
      .page-item .page-link.first:hover, .page-item .page-link.last:hover {
        background: #576a3d;
        color: white;
        border: 1px solid #576a3d; }
    .page-item .page-link:hover {
      background-color: transparent;
      border-color: #728a50;
      color: #576a3d; }
  .page-item.active .page-link {
    background: transparent;
    border: 1px solid #576a3d;
    color: #576a3d; }
  .page-item.disabled .page-link {
    border-color: #d7d7d7;
    color: #d7d7d7;
    background: transparent; }

.page-link {
  background-color: transparent;
  border-color: transparent;
  color: #212121; }

.btn-sm.page-link {
  padding: 0.5rem 0.5rem; }

.pagination-lg .page-item {
  padding: 15px; }
  @media (max-width: 767px) {
    .pagination-lg .page-item {
      padding: 3px; } }
  .pagination-lg .page-item .page-link {
    min-width: 50px;
    height: 50px; }
    @media (max-width: 767px) {
      .pagination-lg .page-item .page-link {
        min-width: 38px;
        height: 38px;
        padding: 0.55rem 0;
        line-height: 1.5;
        font-size: 0.8rem; } }
    @media (max-width: 575px) {
      .pagination-lg .page-item .page-link {
        min-width: 30px;
        height: 30px;
        line-height: 0.9;
        font-size: 0.76rem; } }

.pagination-sm .page-item {
  padding: 6px; }
  @media (max-width: 767px) {
    .pagination-sm .page-item {
      padding: 3px; } }
  .pagination-sm .page-item .page-link {
    min-width: 30px;
    height: 30px;
    line-height: 0.9;
    font-size: 0.76rem; }

/* 24.Tooltip and popover */
.popover {
  border-radius: 0.1rem;
  background-color: white;
  border-color: #d7d7d7; }
  .popover .popover-body {
    color: #212121; }

.popover-header {
  background-color: transparent;
  border-bottom: initial; }

.tooltip-inner {
  padding: 0.5rem 0.75rem;
  color: #212121;
  background-color: white;
  border-radius: 0.1rem;
  border: 1px solid #d7d7d7; }

.tooltip.show {
  opacity: 1; }

.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^="right"] .arrow::before {
  border-right-color: #d7d7d7; }

.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-right-color: white; }

.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^="left"] .arrow::before {
  border-left-color: #d7d7d7; }

.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-left-color: white; }

.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #d7d7d7; }

.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-bottom-color: white; }

.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^="top"] .arrow::before {
  border-top-color: #d7d7d7; }

.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-top-color: white; }

.tooltip .arrow::before,
.tooltip .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid; }

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::after,
.bs-tooltip-auto[x-placement^="right"] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0; }

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  left: 0;
  border-right-color: #d7d7d7; }

.bs-tooltip-right .arrow::after,
.bs-tooltip-auto[x-placement^="right"] .arrow::after {
  left: 1px;
  border-right-color: white; }

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::after,
.bs-tooltip-auto[x-placement^="right"] .arrow::after {
  border-width: 0.4rem 0.4rem 0.4rem 0; }

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  bottom: 0;
  border-top-color: #d7d7d7; }

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::after,
.bs-tooltip-auto[x-placement^="top"] .arrow::after {
  border-width: 0.5rem 0.5rem 0; }

.bs-tooltip-top .arrow::after,
.bs-tooltip-auto[x-placement^="top"] .arrow::after {
  bottom: 1px;
  border-top-color: white; }

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::after,
.bs-tooltip-auto[x-placement^="top"] .arrow::after {
  border-width: 0.4rem 0.4rem 0; }

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::after,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem; }

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  top: 0;
  border-bottom-color: #d7d7d7; }

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::after,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem; }

.bs-tooltip-bottom .arrow::after,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::after {
  top: 1px;
  border-bottom-color: white; }

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::after,
.bs-tooltip-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem; }

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  right: 0;
  border-left-color: #d7d7d7; }

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::after,
.bs-tooltip-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem; }

.bs-tooltip-left .arrow::after,
.bs-tooltip-auto[x-placement^="left"] .arrow::after {
  right: 0;
  border-left-color: white; }

.search-sm {
  position: relative; }
  .search-sm input {
    background: none;
    outline: initial !important;
    border: 1px solid #8f8f8f;
    border-radius: 15px;
    padding: 0.25rem 0.75rem 0.25rem 0.75rem;
    font-size: 0.76rem;
    line-height: 1.3;
    color: #212121; }
  .search-sm:after {
    font-family: "simple-line-icons";
    content: "\e090";
    font-size: 14px;
    border-radius: 10px;
    color: #8f8f8f;
    position: absolute;
    width: 25px;
    right: 2px;
    text-align: center;
    cursor: pointer;
    top: 2px; }

.notification {
  border-radius: 0.1rem;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  padding: 25px 25px 25px 25px; }

.notification:before {
  content: "";
  display: none; }

.notification-primary {
  color: #576a3d;
  background-color: white;
  border: 1px solid #576a3d;
  opacity: 1; }
  .notification-primary .notification-message .title {
    color: #576a3d; }
  .notification-primary .notification-message .message {
    color: #212121; }
  .notification-primary.filled {
    color: white;
    background-color: #576a3d; }
    .notification-primary.filled .notification-message .title {
      color: white; }
    .notification-primary.filled .notification-message .message {
      color: white; }

.notification-secondary {
  color: #dd9c02;
  background-color: white;
  border: 1px solid #dd9c02;
  opacity: 1; }
  .notification-secondary .notification-message .title {
    color: #dd9c02; }
  .notification-secondary .notification-message .message {
    color: #8f8f8f; }
  .notification-secondary.filled {
    color: white;
    background-color: #dd9c02; }
    .notification-secondary.filled .notification-message .title {
      color: white; }
    .notification-secondary.filled .notification-message .message {
      color: white; }

.notification-info {
  color: #3195a5;
  background-color: white;
  border: 1px solid #3195a5;
  opacity: 1; }
  .notification-info .notification-message .title {
    color: #3195a5; }
  .notification-info .notification-message .message {
    color: #8f8f8f; }
  .notification-info.filled {
    color: white;
    background-color: #3195a5; }
    .notification-info.filled .notification-message .title {
      color: white; }
    .notification-info.filled .notification-message .message {
      color: white; }

.notification-success {
  color: #3e884f;
  background-color: white;
  border: 1px solid #3e884f;
  opacity: 1; }
  .notification-success .notification-message .title {
    color: #3e884f; }
  .notification-success .notification-message .message {
    color: #8f8f8f; }
  .notification-success.filled {
    color: white;
    background-color: #3e884f; }
    .notification-success.filled .notification-message .title {
      color: white; }
    .notification-success.filled .notification-message .message {
      color: white; }

.notification-warning {
  color: #b69329;
  background-color: white;
  border: 1px solid #b69329;
  opacity: 1; }
  .notification-warning .notification-message .title {
    color: #b69329; }
  .notification-warning .notification-message .message {
    color: #8f8f8f; }
  .notification-warning.filled {
    color: white;
    background-color: #b69329; }
    .notification-warning.filled .notification-message .title {
      color: white; }
    .notification-warning.filled .notification-message .message {
      color: white; }

.notification-error {
  color: #c43d4b;
  background-color: white;
  border: 1px solid #c43d4b;
  opacity: 1; }
  .notification-error .notification-message .title {
    color: #c43d4b; }
  .notification-error .notification-message .message {
    color: #8f8f8f; }
  .notification-error.filled {
    color: white;
    background-color: #c43d4b; }
    .notification-error.filled .notification-message .title {
      color: white; }
    .notification-error.filled .notification-message .message {
      color: white; }

/* 25.Progress */
.progress {
  background-color: #f3f3f3;
  height: 3px; }

.progress-bar {
  background-color: #576a3d; }

.CircularProgressbar-text {
  fill: #212121 !important; }

.progress-bar-circle {
  width: 54px;
  height: 54px; }
  .progress-bar-circle svg path:first-of-type {
    stroke: #d7d7d7; }
  .progress-bar-circle svg path:last-of-type {
    stroke: #576a3d; }
  .progress-bar-circle.progress-bar-banner svg path:first-of-type {
    stroke: #2f3921; }
  .progress-bar-circle.progress-bar-banner svg path:last-of-type {
    stroke: #fff; }

.progress-banner {
  height: 80px;
  background-image: linear-gradient(to right top, #576a3d, #73904c, #7baa39);
  transition: 0.5s;
  background-size: 200% auto; }
  .progress-banner .CircularProgressbar .CircularProgressbar-text {
    fill: #fff !important; }
  .progress-banner .lead {
    font-size: 1.2rem;
    margin-bottom: 0.5rem; }
    @media (max-width: 1199px) {
      .progress-banner .lead {
        font-size: 1.2rem;
        margin-bottom: 0.2rem; } }
  .progress-banner i {
    font-size: 1.5rem;
    margin-bottom: 1rem; }
    @media (max-width: 1199px) {
      .progress-banner i {
        margin-bottom: 0.2rem; } }
  .progress-banner .progress-bar-circle.progress-bar-banner {
    width: 120px;
    height: 120px; }
    .progress-banner .progress-bar-circle.progress-bar-banner svg path:last-of-type {
      stroke: #e6e6e6; }
    @media (max-width: 1199px) {
      .progress-banner .progress-bar-circle.progress-bar-banner {
        width: 80px;
        height: 80px; } }
  .progress-banner .progress-bar-banner .progressbar-text {
    color: #212121 !important;
    font-size: 1.7rem;
    width: 110px;
    font-weight: 300; }
    @media (max-width: 1199px) {
      .progress-banner .progress-bar-banner .progressbar-text {
        font-size: 1.2rem;
        margin-bottom: 0.2rem; } }
  .progress-banner:hover {
    background-position: right top; }

/* 26.Rating */
.br-theme-bootstrap-stars .br-widget a.br-active:after {
  color: #576a3d; }

.br-theme-bootstrap-stars .br-widget a.br-selected:after {
  color: #576a3d; }

.react-rater {
  display: block !important;
  font-size: 16px;
  color: #d7d7d7; }

.react-rater-star {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "simple-line-icons";
  color: #d7d7d7;
  margin-right: 3px;
  font-size: 18px; }
  .react-rater-star.is-active, .react-rater-star.will-be-active {
    color: #576a3d; }
  .react-rater-star:after {
    content: "\e09b"; }

/* 27.Sortable */
.sortable {
  cursor: default; }

.sortable span {
  vertical-align: middle; }

.sortable-ghost {
  opacity: 0.5; }

/* 28.Spinner */
.spinner {
  width: 36px;
  text-align: center; }

.spinner > span {
  width: 6px;
  height: 6px;
  background-color: #fff;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.2s infinite ease-in-out both;
  animation: sk-bouncedelay 1.2s infinite ease-in-out both; }

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s; }

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s; }

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0); }
  40% {
    -webkit-transform: scale(1); } }

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

/* 29.Croppper */
.cropper-line {
  background-color: #576a3d; }

.cropper-point {
  background-color: #576a3d; }

.cropper-view-box {
  outline-color: #576a3d;
  outline: #576a3d; }

.cropper-preview {
  overflow: hidden; }

#cropperContainer {
  height: 300px;
  display: none; }

/* 30.Modal */
.modal .modal-header,
.modal .modal-body,
.modal .modal-footer {
  padding: 1.75rem; }

.modal .modal-header {
  border-bottom: 1px solid #d7d7d7; }

.modal .modal-footer {
  border-top: 1px solid #d7d7d7; }

.modal .close {
  color: #212121;
  text-shadow: initial; }

.modal-content {
  border: initial;
  border-radius: 0.1rem;
  background: white; }

.modal-dialog {
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1); }

.modal-right {
  padding-right: 0 !important; }
  .modal-right .modal-dialog {
    margin: 0 auto;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    height: 100%;
    max-width: 380px; }
  .modal-right .modal-content {
    min-height: 100%; }
  .modal-right .modal-header {
    height: 80px; }
    @media (max-width: 1439px) {
      .modal-right .modal-header {
        height: 80px; } }
    @media (max-width: 1199px) {
      .modal-right .modal-header {
        height: 80px; } }
    @media (max-width: 767px) {
      .modal-right .modal-header {
        height: 70px; } }
  .modal-right .modal-footer {
    justify-content: center; }
  .modal-right .modal.fade .modal-dialog {
    transform: translate(25%, 0) !important; }
  .modal-right .modal.show .modal-dialog {
    transform: translate(0, 0) !important; }

.modal-right-teams {
  padding-right: 0 !important; }
  .modal-right-teams .modal-dialog {
    margin: 0 auto;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    height: 100%;
    max-width: 500px; }
  .modal-right-teams .modal-content {
    min-height: 100%; }
  .modal-right-teams .modal-header {
    height: 80px; }
    @media (max-width: 1439px) {
      .modal-right-teams .modal-header {
        height: 80px; } }
    @media (max-width: 1199px) {
      .modal-right-teams .modal-header {
        height: 80px; } }
    @media (max-width: 767px) {
      .modal-right-teams .modal-header {
        height: 70px; } }
  .modal-right-teams .modal-footer {
    justify-content: center; }
  .modal-right-teams .modal.fade .modal-dialog {
    transform: translate(25%, 0) !important; }
  .modal-right-teams .modal.show .modal-dialog {
    transform: translate(0, 0) !important; }

/* 31.Authorization */
.auth-card {
  display: flex;
  flex-direction: row;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04); }
  .auth-card .image-side {
    width: 40%;
    background: url("/assets/img/login-balloon.jpg") no-repeat center top;
    background-size: cover;
    padding: 80px 40px; }
    .auth-card .image-side .h3 {
      line-height: 0.8rem; }
  .auth-card .form-side {
    width: 60%;
    padding: 80px; }
  @media (max-width: 991px) {
    .auth-card {
      flex-direction: column; }
      .auth-card .image-side {
        width: 100%;
        padding: 60px; }
      .auth-card .form-side {
        width: 100%;
        padding: 60px; } }
  @media (max-width: 767px) {
    .auth-card p.h2 {
      font-size: 1.6rem; } }
  @media (max-width: 575px) {
    .auth-card {
      flex-direction: column; }
      .auth-card .image-side {
        padding: 35px 30px; }
      .auth-card .form-side {
        padding: 35px 30px; }
      .auth-card .logo-single {
        margin-bottom: 20px; }
      .auth-card p.h2 {
        font-size: 1.4rem; } }

/* 32.Html Editors */
.html-editor {
  height: 350px; }

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #576a3d; }

.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: #576a3d; }

.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-mitter,
.ql-snow .ql-toolbar button:hover .ql-stroke-mitter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-mitter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-mitter {
  stroke: #576a3d; }

.ql-toolbar.ql-snow {
  border-color: #d7d7d7; }

.ql-container.ql-snow {
  border-color: #d7d7d7; }

.html-editor-bubble {
  height: 200px; }

.html-editor-bubble .ql-editor {
  border: 1px solid #d7d7d7; }

.ql-tooltip {
  z-index: 4; }

.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  height: 350px; }

.ck.ck-content.ck-editor__editable.ck-rounded-corners {
  box-shadow: initial !important; }

.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-focused {
  border-color: #d7d7d7; }

.ck.ck-toolbar {
  background: !important; }

.RichEditor-root {
  background: white;
  border: 1px solid #d7d7d7;
  font-family: "Nunito", sans-serif;
  font-size: 14px;
  padding: 15px; }

.RichEditor-editor {
  border-top: 1px solid #d7d7d7;
  cursor: text;
  font-size: 14px;
  margin-top: 10px; }

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px; }

.RichEditor-editor .public-DraftEditor-content {
  min-height: 200px; }

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none; }

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #d7d7d7;
  color: #8f8f8f;
  font-family: "Hoefler Text", "Georgia", serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px; }

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: #8f8f8f;
  font-family: "Inconsolata", "Menlo", "Consolas", monospace;
  font-size: 16px;
  padding: 20px; }

.RichEditor-controls {
  font-family: "Nunito", sans-serif;
  font-size: 12px;
  margin-bottom: 5px;
  user-select: none; }

.RichEditor-styleButton {
  color: #d7d7d7;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
  color: #212121;
  font-size: 0.76rem; }

.RichEditor-activeButton {
  color: #212121; }

.ql-editor {
  height: 200px; }

.ql-container.ql-bubble {
  border: 1px solid #d7d7d7; }

/* 33.Icons */
.simple-line-icons .glyph,
.mind-icons .glyph {
  width: 14.28%;
  text-align: center;
  float: left;
  height: 100px; }
  .simple-line-icons .glyph .glyph-icon,
  .simple-line-icons .glyph .fa,
  .mind-icons .glyph .glyph-icon,
  .mind-icons .glyph .fa {
    font-size: 32px; }
  .simple-line-icons .glyph .author-name,
  .mind-icons .glyph .author-name {
    display: none; }
  .simple-line-icons .glyph .class-name,
  .mind-icons .glyph .class-name {
    font-size: 0.76rem;
    color: #909090 !important; }
  @media (max-width: 1199px) {
    .simple-line-icons .glyph,
    .mind-icons .glyph {
      width: 16.66%; } }
  @media (max-width: 991px) {
    .simple-line-icons .glyph,
    .mind-icons .glyph {
      width: 20%; } }
  @media (max-width: 767px) {
    .simple-line-icons .glyph,
    .mind-icons .glyph {
      width: 25%; } }
  @media (max-width: 575px) {
    .simple-line-icons .glyph,
    .mind-icons .glyph {
      width: 50%; } }

.chart-container {
  height: 300px; }

.theme-colors {
  width: 280px;
  position: fixed;
  z-index: 1030;
  top: 50%;
  right: 0;
  background: white;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  transform: translate(280px, -50%);
  transition: transform 0.4s ease-out;
  padding-top: 10px;
  padding-bottom: 10px; }
  .theme-colors .theme-button {
    position: absolute;
    left: -34px;
    background: white;
    padding: 13px 7px 13px 7px;
    border-radius: 0.2rem;
    color: #212121;
    box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.04);
    font-size: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: #576a3d; }
  .theme-colors .theme-color {
    width: 24px;
    height: 24px;
    display: inline-block;
    border-radius: 20px;
    transition: background 0.25s; }
    .theme-colors .theme-color.active, .theme-colors .theme-color:hover {
      background: white; }
  .theme-colors .theme-color-purple {
    border: 3px solid #922c88;
    background: #922c88; }
  .theme-colors .theme-color-blue {
    border: 3px solid #145388;
    background: #145388; }
  .theme-colors .theme-color-orange {
    border: 3px solid #e2863b;
    background: #e2863b; }
  .theme-colors .theme-color-green {
    border: 3px solid #576a3d;
    background: #576a3d; }
  .theme-colors .theme-color-red {
    border: 3px solid #880a1f;
    background: #880a1f; }
  .theme-colors.shown {
    transform: translate(0, -50%); }

/* 34.Floating Label */
.has-float-label {
  display: block;
  position: relative; }

.has-float-label label::after,
.has-float-label > span::after {
  background: white !important; }

.has-top-label label,
.has-top-label > span,
.has-float-label label,
.has-float-label > span {
  color: rgba(33, 33, 33, 0.7); }

.has-float-label label,
.has-float-label > span:last-of-type {
  position: absolute;
  cursor: text;
  font-size: 90%;
  opacity: 1;
  top: -0.4em;
  left: 0.75rem;
  z-index: 3;
  line-height: 1;
  padding: 0 1px; }

.has-float-label label::after,
.has-float-label > span::after {
  content: " ";
  display: block;
  position: absolute;
  height: 5px;
  top: 3px;
  left: -0.2em;
  right: -0.2em;
  z-index: -1; }

.has-float-label .form-control::-webkit-input-placeholder {
  opacity: 1; }

.has-float-label .form-control::-moz-placeholder {
  opacity: 1; }

.has-float-label .form-control:-ms-input-placeholder {
  opacity: 1; }

.has-float-label .form-control::placeholder {
  opacity: 1; }

.has-float-label
.form-control:placeholder-shown:not(:focus)::-webkit-input-placeholder {
  opacity: 0; }

.has-float-label .form-control:placeholder-shown:not(:focus)::-moz-placeholder {
  opacity: 0; }

.has-float-label
.form-control:placeholder-shown:not(:focus):-ms-input-placeholder {
  opacity: 0; }

.has-float-label .form-control:placeholder-shown:not(:focus)::placeholder {
  opacity: 0; }

.input-group .has-float-label {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin-bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }

.input-group .has-float-label .form-control {
  width: 100%; }

.input-group .has-float-label:not(:last-child),
.input-group .has-float-label:not(:last-child) .form-control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 0; }

.input-group .has-float-label:not(:first-child),
.input-group .has-float-label:not(:first-child) .form-control {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0; }

.form-group.has-top-label .form-control,
.form-group.has-top-label .react-tagsinput,
.form-group.has-top-label .react-datepicker__input-container input,
.form-group.has-top-label .react-select__value-container {
  padding: 1.7rem 0.75rem 0.5rem 0.75rem !important; }

.has-top-label {
  display: block;
  position: relative; }
  .has-top-label .react-tagsinput-input {
    padding: 0;
    margin: 0;
    font-size: 0.8rem;
    line-height: 1; }

.has-top-label .react-select__value-container .css-rsyb7x {
  margin: 0 !important;
  padding: 0 !important; }

.has-top-label label,
.has-top-label > span:last-of-type {
  position: absolute;
  cursor: text;
  font-size: 76%;
  opacity: 1;
  top: 0.7rem;
  left: 0.75rem;
  z-index: 3;
  line-height: 1;
  padding: 0 1px;
  font-weight: 600; }

.has-top-label label::after,
.has-top-label > span::after {
  content: " ";
  display: block;
  position: absolute;
  height: 2px;
  top: 50%;
  left: -0.2em;
  right: -0.2em;
  z-index: -1; }

.has-top-label .form-control::-webkit-input-placeholder {
  opacity: 1; }

.has-top-label .form-control::-moz-placeholder {
  opacity: 1; }

.has-top-label .form-control:-ms-input-placeholder {
  opacity: 1; }

.has-top-label .form-control::placeholder {
  opacity: 1; }

.has-top-label
.form-control:placeholder-shown:not(:focus)::-webkit-input-placeholder {
  opacity: 0; }

.has-top-label .form-control:placeholder-shown:not(:focus)::-moz-placeholder {
  opacity: 0; }

.has-top-label
.form-control:placeholder-shown:not(:focus):-ms-input-placeholder {
  opacity: 0; }

.has-top-label .form-control:placeholder-shown:not(:focus)::placeholder {
  opacity: 0; }

.has-top-label .form-control:placeholder-shown:not(:focus) + * {
  font-size: 150%;
  opacity: 0.5;
  top: 0.3em; }

.has-top-label .react-select__single-value {
  top: unset !important;
  bottom: -3px !important;
  margin-left: 0 !important; }

.input-group .has-top-label {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin-bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }

.input-group .has-top-label .form-control {
  width: 100%; }

.input-group .has-top-label:not(:last-child),
.input-group .has-top-label:not(:last-child) .form-control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 0; }

.input-group .has-top-label:not(:first-child),
.input-group .has-top-label:not(:first-child) .form-control {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0; }

.form-group.has-top-label .form-control,
.form-group.has-top-label .react-tagsinput,
.form-group.has-top-label .react-datepicker__input-container input,
.form-group.has-top-label .react-select__value-container {
  min-height: calc(3.3rem + 2px) !important; }

.form-group.has-top-label select.form-control:not([size]):not([multiple]) {
  height: calc(3.3rem + 2px);
  padding: 1.7rem 0.75rem 0.5rem 0.5rem; }

/* 35.Loading */
.loading {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 2px solid rgba(87, 106, 61, 0.2);
  border-radius: 50%;
  border-top-color: #576a3d;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  position: fixed;
  z-index: 1; }

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg); } }

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg); } }

/* 36.Context Menu */
.react-contextmenu {
  box-shadow: initial;
  border-radius: 0.1rem;
  background: white;
  border: 1px solid rgba(33, 33, 33, 0.15);
  padding: 0.5rem 0;
  opacity: 0; }

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
  z-index: 9999; }

.react-contextmenu-item {
  padding: 0.5rem 1.5rem;
  background: white;
  color: #212121;
  cursor: pointer; }
  .react-contextmenu-item span {
    font-family: "Nunito", sans-serif;
    font-size: 0.8rem;
    font-weight: 400;
    margin-left: 0.5rem;
    line-height: 15px; }
  .react-contextmenu-item:hover {
    color: #212121;
    text-decoration: none;
    background-color: #f8f8f8; }

.react-contextmenu-item:not(.react-contextmenu-item--disabled):hover {
  color: #212121;
  text-decoration: none;
  background-color: #f8f8f8; }

.card.react-contextmenu--visible,
.card.active {
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1); }

/* Videojs */
.video-js .vjs-big-play-button {
  background: white;
  height: 1.2em;
  border-radius: 0.75em;
  line-height: initial;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 3.5em;
  width: 2.5em;
  border: 0.06666em solid white; }
  .video-js .vjs-big-play-button .vjs-icon-placeholder {
    color: #576a3d; }

.video-js:hover .vjs-big-play-button,
.video-js .vjs-big-play-button:focus {
  background-color: #f2f2f2;
  border-color: #f2f2f2; }

.vjs-control {
  text-shadow: initial !important;
  outline: initial !important; }

.video-js .vjs-control-bar {
  background: initial;
  margin: 1.75rem;
  width: calc(100% - 3.5rem); }
  .video-js .vjs-control-bar .vjs-control.vjs-button,
  .video-js .vjs-control-bar .vjs-remaining-time,
  .video-js .vjs-control-bar .vjs-volume-panel {
    margin-right: 0.5em;
    background: white;
    color: #576a3d;
    border-radius: 15px;
    box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1); }
  .video-js .vjs-control-bar .vjs-progress-control.vjs-control {
    background: white;
    border-radius: 15px;
    margin-right: 0.5em;
    box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1); }
  .video-js .vjs-control-bar .vjs-mute-control.vjs-control {
    box-shadow: initial; }
  .video-js .vjs-control-bar .vjs-progress-holder {
    height: 3px;
    font-size: 1.6em !important; }
  .video-js .vjs-control-bar .vjs-load-progress,
  .video-js .vjs-control-bar .vjs-load-progress div {
    background-color: rgba(87, 106, 61, 0.2); }
  .video-js .vjs-control-bar .vjs-play-progress:before {
    font-size: 0.55em;
    top: -0.2em; }
  .video-js .vjs-control-bar .vjs-progress-holder {
    margin: 0 17px; }
  .video-js .vjs-control-bar .vjs-slider {
    text-shadow: initial !important;
    outline: initial !important;
    background-color: #dadada; }
  .video-js .vjs-control-bar .vjs-play-progress {
    background: #576a3d; }
  .video-js .vjs-control-bar .vjs-play-progress:before {
    color: #576a3d; }
  .video-js .vjs-control-bar .vjs-volume-horizontal {
    margin-left: -1.5em;
    width: 4em; }
  .video-js .vjs-control-bar .vjs-volume-panel .vjs-volume-level {
    background: #576a3d; }

.video-js.audio {
  background: initial; }
  .video-js.audio .vjs-big-play-button {
    display: none; }
  .video-js.audio .vjs-control-bar {
    display: flex; }
  .video-js.audio .vjs-fullscreen-control {
    display: none; }
  .video-js.audio .vjs-control-bar {
    margin-bottom: 0; }
  .video-js.audio .vjs-control.vjs-button,
  .video-js.audio .vjs-remaining-time,
  .video-js.audio .vjs-volume-panel {
    box-shadow: 0 0px 2px rgba(0, 0, 0, 0.15), 0 0px 1px rgba(0, 0, 0, 0.2); }
  .video-js.audio .vjs-progress-control.vjs-control {
    box-shadow: 0 0px 2px rgba(0, 0, 0, 0.15), 0 0px 1px rgba(0, 0, 0, 0.2); }
  .video-js.audio .vjs-mute-control {
    box-shadow: initial !important; }
  .video-js.audio .vjs-loading-spinner {
    display: none !important; }

/* 38.Landing Page */
.landing-page {
  font-size: 1rem;
  line-height: 1.6rem;
  color: #8f8f8f; }
  @media (max-width: 767px) {
    .landing-page {
      font-size: 0.9rem;
      line-height: 1.4rem; } }
  .landing-page .mobile-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    transform: translateX(300px);
    z-index: 25;
    width: 300px;
    background: #f8f8f8;
    box-shadow: initial;
    transition: transform 0.5s, box-shadow 0.5s; }
  .landing-page .logo-mobile {
    display: block;
    text-align: center;
    padding: 3rem 2rem; }
    .landing-page .logo-mobile span {
      display: inline-block;
      width: 120px;
      height: 30px;
      background: url("/assets/img/logo-black.svg");
      background-repeat: no-repeat; }
  .landing-page .main-container {
    overflow: hidden; }
  .landing-page.show-mobile-menu .mobile-menu {
    transform: translateX(0);
    box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1); }
  .landing-page.show-mobile-menu .main-container .content-container,
  .landing-page.show-mobile-menu .main-container .landing-page-nav {
    transform: translateX(-300px); }
  .landing-page .content-container {
    transition: transform 0.5s; }
  .landing-page p {
    font-size: 1rem;
    line-height: 1.6rem;
    color: #8f8f8f; }
    @media (max-width: 767px) {
      .landing-page p {
        font-size: 0.9rem;
        line-height: 1.4rem; } }
  .landing-page h1 {
    font-size: 3rem;
    color: #576a3d;
    margin-bottom: 2rem; }
    @media (max-width: 767px) {
      .landing-page h1 {
        font-size: 2rem; } }
  .landing-page h2 {
    font-size: 1.8rem;
    color: #576a3d;
    margin-bottom: 2rem; }
    @media (max-width: 991px) {
      .landing-page h2 {
        font-size: 1.6rem; } }
    @media (max-width: 767px) {
      .landing-page h2 {
        font-size: 1.2rem; } }
  .landing-page h3 {
    font-size: 1.4rem;
    line-height: 1.4;
    color: #576a3d; }
    @media (max-width: 767px) {
      .landing-page h3 {
        font-size: 1.1rem; } }
  .landing-page .semi-rounded {
    border-radius: 1.2rem !important; }
  .landing-page .dropdown-toggle::after {
    opacity: 0.7; }
  .landing-page .btn-outline-semi-light {
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: #fff;
    background: transparent; }
    .landing-page .btn-outline-semi-light:hover, .landing-page .btn-outline-semi-light:active {
      border: 1px solid #3c4a2a;
      background: #3c4a2a; }
  .landing-page .landing-page-nav {
    z-index: 5 !important;
    top: 0;
    position: fixed;
    width: 100%;
    background: transparent;
    box-shadow: initial;
    transition: transform 0.5s; }
    .landing-page .landing-page-nav .headroom {
      transition: top 0.5s, background 0.5s, box-shadow 0.2s, opacity 0.2s, transform 0.5s !important; }
    .landing-page .landing-page-nav .container {
      height: 120px; }
      @media (max-width: 1439px) {
        .landing-page .landing-page-nav .container {
          height: 110px; } }
      @media (max-width: 1199px) {
        .landing-page .landing-page-nav .container {
          height: 100px; } }
      @media (max-width: 767px) {
        .landing-page .landing-page-nav .container {
          height: 90px; } }
    .landing-page .landing-page-nav .btn {
      padding-right: 1.75rem !important;
      padding-left: 1.75rem !important; }
    .landing-page .landing-page-nav .navbar-logo {
      width: 120px;
      height: 40px; }
      .landing-page .landing-page-nav .navbar-logo .white {
        display: inline-block;
        width: 120px;
        height: 40px;
        background: url("/assets/img/logo-white.svg");
        background-repeat: no-repeat; }
      .landing-page .landing-page-nav .navbar-logo .dark {
        width: 120px;
        height: 40px;
        background: url("/assets/img/logo-black.svg");
        background-repeat: no-repeat;
        display: none; }
    .landing-page .landing-page-nav .mobile-menu-button {
      font-size: 20px;
      display: none; }
      @media (max-width: 991px) {
        .landing-page .landing-page-nav .mobile-menu-button {
          display: block; } }
    .landing-page .landing-page-nav .navbar-nav {
      height: 100%;
      position: relative; }
      .landing-page .landing-page-nav .navbar-nav li {
        height: 100%;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        position: relative; }
        .landing-page .landing-page-nav .navbar-nav li:last-of-type {
          padding-right: 0; }
        .landing-page .landing-page-nav .navbar-nav li.active::before {
          content: " ";
          background: #fff;
          border-radius: 10px;
          position: absolute;
          width: calc(100% - 1.5rem);
          height: 4px;
          left: 0;
          right: 0;
          margin: 0 auto;
          top: 0; }
        @media (max-width: 1199px) {
          .landing-page .landing-page-nav .navbar-nav li {
            padding-left: 1rem;
            padding-right: 1rem; }
            .landing-page .landing-page-nav .navbar-nav li:last-of-type {
              padding-right: 0; } }
      .landing-page .landing-page-nav .navbar-nav .nav-item > a,
      .landing-page .landing-page-nav .navbar-nav .nav-item > .dropdown > .dropdown-toggle {
        color: #fff;
        font-family: Nunito, sans-serif;
        font-size: 1rem;
        display: inline-block;
        transform: translateY(-50%);
        margin-top: 57px; }
        @media (max-width: 1439px) {
          .landing-page .landing-page-nav .navbar-nav .nav-item > a,
          .landing-page .landing-page-nav .navbar-nav .nav-item > .dropdown > .dropdown-toggle {
            margin-top: 53px; } }
        @media (max-width: 1199px) {
          .landing-page .landing-page-nav .navbar-nav .nav-item > a,
          .landing-page .landing-page-nav .navbar-nav .nav-item > .dropdown > .dropdown-toggle {
            margin-top: 50px; } }
      .landing-page .landing-page-nav .navbar-nav .nav-item > .dropdown > .dropdown-toggle {
        padding: 0 !important; }
      .landing-page .landing-page-nav .navbar-nav .nav-item:not(.active) > a:hover:not(.btn), .landing-page .landing-page-nav .navbar-nav .nav-item:not(.active) > a:active:not(.btn),
      .landing-page .landing-page-nav .navbar-nav .nav-item:not(.active) .dropdown > .dropdown-toggle:hover:not(.btn),
      .landing-page .landing-page-nav .navbar-nav .nav-item:not(.active) .dropdown > .dropdown-toggle:active:not(.btn) {
        opacity: 0.8; }
      .landing-page .landing-page-nav .navbar-nav .dropdown {
        height: 80px;
        position: relative; }
        .landing-page .landing-page-nav .navbar-nav .dropdown:hover > .dropdown-menu {
          display: block; }
        .landing-page .landing-page-nav .navbar-nav .dropdown > .dropdown-toggle:active {
          pointer-events: none; }
        .landing-page .landing-page-nav .navbar-nav .dropdown .dropdown-menu {
          position: absolute;
          margin-top: 0; }
        .landing-page .landing-page-nav .navbar-nav .dropdown a.dropdown-item {
          font-size: 0.8rem;
          color: #212121;
          padding-bottom: 0.5rem;
          outline: initial; }
          .landing-page .landing-page-nav .navbar-nav .dropdown a.dropdown-item.active, .landing-page .landing-page-nav .navbar-nav .dropdown a.dropdown-item:active {
            color: #fff; }
  .landing-page .landing-page-nav .headroom--unpinned {
    transform: translate3d(0px, -100%, 0px) !important; }
  .landing-page .landing-page-nav .headroom--scrolled.headroom--pinned {
    background: white;
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04); }
    .landing-page .landing-page-nav .headroom--scrolled.headroom--pinned .navbar-logo .white {
      display: none; }
    .landing-page .landing-page-nav .headroom--scrolled.headroom--pinned .navbar-logo .dark {
      display: inline-block; }
    .landing-page .landing-page-nav .headroom--scrolled.headroom--pinned .navbar-nav a,
    .landing-page .landing-page-nav .headroom--scrolled.headroom--pinned .navbar-nav .dropdown-toggle {
      color: #212121; }
      .landing-page .landing-page-nav .headroom--scrolled.headroom--pinned .navbar-nav a:hover, .landing-page .landing-page-nav .headroom--scrolled.headroom--pinned .navbar-nav a:active,
      .landing-page .landing-page-nav .headroom--scrolled.headroom--pinned .navbar-nav .dropdown-toggle:hover,
      .landing-page .landing-page-nav .headroom--scrolled.headroom--pinned .navbar-nav .dropdown-toggle:active {
        color: #576a3d; }
    .landing-page .landing-page-nav .headroom--scrolled.headroom--pinned .navbar-nav .dropdown-item {
      color: #212121; }
      .landing-page .landing-page-nav .headroom--scrolled.headroom--pinned .navbar-nav .dropdown-item:focus {
        color: #fff; }
    .landing-page .landing-page-nav .headroom--scrolled.headroom--pinned .navbar-nav li.active::before {
      content: " ";
      background-color: #576a3d; }
    .landing-page .landing-page-nav .headroom--scrolled.headroom--pinned .navbar-nav .btn-outline-semi-light {
      border: 1px solid rgba(87, 106, 61, 0.3);
      color: #576a3d; }
      .landing-page .landing-page-nav .headroom--scrolled.headroom--pinned .navbar-nav .btn-outline-semi-light:hover, .landing-page .landing-page-nav .headroom--scrolled.headroom--pinned .navbar-nav .btn-outline-semi-light:active {
        border: 1px solid #576a3d;
        background: #576a3d;
        color: #fff; }
    .landing-page .landing-page-nav .headroom--scrolled.headroom--pinned.headroom--unpinned {
      background: transparent; }
  .landing-page .landing-page-nav .headroom--unfixed .mobile-menu-button {
    color: #fff; }
  .landing-page .section {
    margin-bottom: 150px;
    padding-top: 100px;
    padding-bottom: 100px; }
    @media (max-width: 991px) {
      .landing-page .section {
        margin-bottom: 100px;
        padding-top: 60px; } }
    .landing-page .section.home {
      background: url("/assets/img/landing-page/home-background-green.png");
      background-repeat: no-repeat;
      background-position-y: 0;
      background-color: transparent;
      height: 1440px;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
      position: relative; }
      @media (max-width: 575px) {
        .landing-page .section.home {
          background-position-y: -100px;
          height: 1340px; } }
    .landing-page .section.subpage {
      background: url("/assets/img/landing-page/sub-background-green.png");
      height: 690px;
      background-position-y: -350px;
      background-repeat: no-repeat; }
      @media (max-width: 1439px) {
        .landing-page .section.subpage {
          background-position-y: -400px;
          height: 640px; } }
      @media (max-width: 991px) {
        .landing-page .section.subpage {
          background-position-y: -540px;
          height: 520px; } }
      @media (max-width: 767px) {
        .landing-page .section.subpage {
          background-position-y: -620px;
          height: 450px; } }
    .landing-page .section.subpage-long {
      background: url("/assets/img/landing-page/sub-background-green.png");
      height: 900px;
      background-repeat: no-repeat;
      background-position-y: -180px; }
      @media (max-width: 991px) {
        .landing-page .section.subpage-long {
          background-position-y: -220px;
          height: 830px; } }
      @media (max-width: 767px) {
        .landing-page .section.subpage-long {
          background-position-y: -310px;
          height: 760px; } }
    .landing-page .section.footer {
      background: url("/assets/img/landing-page/footer-background-green.png");
      background-color: white;
      background-repeat: no-repeat;
      padding-bottom: 0; }
  .landing-page .section.background {
    background: white;
    position: relative;
    padding-top: 100px;
    padding-bottom: 100px; }
    @media (max-width: 991px) {
      .landing-page .section.background {
        padding-top: 60px;
        padding-bottom: 60px; } }
    .landing-page .section.background::before {
      content: " ";
      width: 100%;
      height: 70px;
      position: absolute;
      top: -70px;
      background: url("/assets/img/landing-page/white-background-top.png");
      background-size: cover;
      background-position: center; }
    .landing-page .section.background::after {
      content: " ";
      width: 100%;
      height: 70px;
      position: absolute;
      bottom: -70px;
      background: url("/assets/img/landing-page/white-background-bottom.png");
      background-size: cover;
      background-position: center; }
    .landing-page .section.background.background-no-bottom::after {
      content: "";
      background: initial;
      width: initial;
      height: initial; }
  .landing-page .background-white {
    background: #fff; }
  .landing-page .mobile-hero {
    margin-left: 50%;
    transform: translateX(-50%);
    max-width: 135%;
    margin-bottom: 3rem; }
  .landing-page .home-row {
    padding-top: 180px;
    margin-bottom: 70px; }
    @media (max-width: 1439px) {
      .landing-page .home-row {
        padding-top: 120px; } }
    @media (max-width: 991px) {
      .landing-page .home-row {
        padding-top: 110px; } }
  .landing-page .home-text {
    margin-top: 40px; }
    .landing-page .home-text p {
      color: #fff;
      font-size: 1.1rem; }
      @media (max-width: 767px) {
        .landing-page .home-text p {
          font-size: 0.9rem;
          line-height: 1.4rem; } }
    .landing-page .home-text .display-1 {
      font-size: 3rem;
      line-height: 3.2rem;
      margin-bottom: 1.1em;
      color: #fff; }
      @media (max-width: 991px) {
        .landing-page .home-text .display-1 {
          font-size: 1.8rem;
          line-height: 2.5rem; } }
      @media (max-width: 767px) {
        .landing-page .home-text .display-1 {
          font-size: 1.7rem;
          line-height: 2.3rem; } }
      @media (max-width: 575px) {
        .landing-page .home-text .display-1 {
          font-size: 1.6rem;
          line-height: 2.2rem; } }
    @media (max-width: 767px) {
      .landing-page .home-text {
        margin-top: 0; } }
  .landing-page .home-carousel .card .detail-text {
    padding: 1rem;
    margin-bottom: 0;
    min-height: 110px; }
    @media (max-width: 767px) {
      .landing-page .home-carousel .card .detail-text {
        padding: 0.25rem;
        min-height: 55px; } }
  @media (max-width: 767px) {
    .landing-page .home-carousel .card .btn-link {
      padding: 0.25rem; } }
  .landing-page i.large-icon {
    font-size: 60px;
    line-height: 110px;
    background: -webkit-gradient(linear, left top, left bottom, from(#dd9c02), to(#576a3d));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: initial; }
    @media (max-width: 767px) {
      .landing-page i.large-icon {
        line-height: 90px; } }
  .landing-page .btn-circle {
    padding: 0;
    width: 50px;
    height: 50px;
    border-radius: 30px;
    text-align: center;
    font-size: 14px; }
  .landing-page .btn-circle.hero-circle-button {
    position: absolute;
    bottom: 80px;
    margin-left: 10px; }
    .landing-page .btn-circle.hero-circle-button i {
      padding-top: 18px;
      display: inline-block; }
  .landing-page .btn-link {
    text-decoration: initial !important; }
  .landing-page .newsletter-input-container {
    margin-top: 5rem; }
    .landing-page .newsletter-input-container .input-group {
      border-radius: 50px;
      box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1); }
    .landing-page .newsletter-input-container input {
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
      border-color: transparent !important;
      padding: 0.75rem 1.75rem 0.75rem;
      font-size: 0.9rem; }
    @media (max-width: 767px) {
      .landing-page .newsletter-input-container button {
        padding: 1rem 1.5rem 0.9rem; } }
  .landing-page .footer .footer-content {
    padding-top: 210px; }
    @media (max-width: 767px) {
      .landing-page .footer .footer-content {
        padding-top: 140px; } }
    @media (max-width: 575px) {
      .landing-page .footer .footer-content {
        padding-top: 80px; } }
  .landing-page .footer .footer-circle-button {
    margin-top: 80px;
    margin-right: 10px; }
    @media (max-width: 991px) {
      .landing-page .footer .footer-circle-button {
        margin-top: 110px; } }
    .landing-page .footer .footer-circle-button i {
      padding-top: 15px;
      display: inline-block; }
  .landing-page .footer .footer-logo {
    width: 140px;
    margin-bottom: 70px; }
  .landing-page .footer .footer-menu p {
    color: #fff;
    font-size: 1rem; }
  .landing-page .footer .footer-menu a {
    cursor: pointer;
    font-size: 1rem;
    color: #fff; }
    .landing-page .footer .footer-menu a:hover, .landing-page .footer .footer-menu a:focus {
      color: #222918; }
  .landing-page .footer .footer-menu .collapse-button i {
    font-size: 0.75rem;
    margin-left: 5px; }
  .landing-page .footer .footer-menu .collapse-button:hover, .landing-page .footer .footer-menu .collapse-button:focus {
    color: #fff; }
  .landing-page .footer .separator {
    opacity: 0.3; }
  .landing-page .footer .copyright p {
    color: #fff; }
  .landing-page .footer .social-icons ul {
    margin-bottom: 0; }
  .landing-page .footer .social-icons li {
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
    .landing-page .footer .social-icons li a {
      color: #fff;
      font-size: 1.2rem; }
      .landing-page .footer .social-icons li a:hover, .landing-page .footer .social-icons li a:active {
        color: rgba(255, 255, 255, 0.7); }
  .landing-page .feature-image-left {
    float: right; }
  @media (max-width: 767px) {
    .landing-page .feature-image-left,
    .landing-page .feature-image-right {
      margin-left: 50%;
      transform: translateX(-50%);
      max-width: 115%;
      float: initial; } }
  @media (max-width: 767px) {
    .landing-page .heading-team {
      text-align: center; } }
  .landing-page .mobile-menu {
    text-align: center; }
    .landing-page .mobile-menu img {
      width: 100px;
      margin-top: 3rem;
      margin-bottom: 3rem; }
    .landing-page .mobile-menu ul {
      text-align: left; }
      .landing-page .mobile-menu ul li {
        padding: 0.5rem 2.5rem; }
        .landing-page .mobile-menu ul li.active a {
          color: #576a3d; }
        .landing-page .mobile-menu ul li a {
          font-size: 0.9rem; }
    .landing-page .mobile-menu .dropdown .dropdown-menu {
      position: static !important;
      background: initial;
      border: initial;
      padding: initial;
      margin: initial;
      transform: initial !important;
      margin-top: 0.5rem; }
    .landing-page .mobile-menu .dropdown a.dropdown-item {
      color: #212121;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      background: initial !important; }
      .landing-page .mobile-menu .dropdown a.dropdown-item.active, .landing-page .mobile-menu .dropdown a.dropdown-item:active {
        color: #576a3d; }
  @media (max-width: 991px) {
    .landing-page .side-bar {
      margin-top: 5rem; } }
  .landing-page .side-bar .side-bar-content {
    margin-bottom: 5rem; }
  @media (max-width: 767px) {
    .landing-page .side-bar h2 {
      margin-bottom: 1rem; } }
  .landing-page .listing-card-container {
    background: initial;
    box-shadow: initial; }
    .landing-page .listing-card-container .listing-desc {
      line-height: 1.4;
      font-size: 1rem; }
  .landing-page .table-heading {
    box-shadow: initial;
    background: initial; }
  .landing-page form.dark-background input:-webkit-autofill {
    color: white !important;
    -webkit-text-fill-color: white !important; }
  .landing-page form.dark-background .has-float-label > span,
  .landing-page form.dark-background .has-float-label label,
  .landing-page form.dark-background .has-top-label > span,
  .landing-page form.dark-background .has-top-label label {
    color: rgba(255, 255, 255, 0.6); }
  .landing-page form.dark-background .bootstrap-tagsinput,
  .landing-page form.dark-background .form-control {
    background: transparent;
    border-color: rgba(255, 255, 255, 0.3);
    color: #fff; }
    .landing-page form.dark-background .bootstrap-tagsinput:focus, .landing-page form.dark-background .bootstrap-tagsinput:active,
    .landing-page form.dark-background .form-control:focus,
    .landing-page form.dark-background .form-control:active {
      border-color: rgba(255, 255, 255, 0.6); }
  .landing-page .has-float-label > span,
  .landing-page .has-float-label label,
  .landing-page .has-top-label > span,
  .landing-page .has-top-label label {
    font-size: 64%; }
  .landing-page .video-js.blog-video {
    width: 100%;
    height: 442px;
    background-color: #f8f8f8; }
    @media (max-width: 991px) {
      .landing-page .video-js.blog-video {
        height: 280px; } }
    .landing-page .video-js.blog-video .vjs-poster {
      background-size: cover; }
  .landing-page .video-js.side-bar-video {
    width: 100%;
    height: 280px;
    background-color: #f8f8f8;
    overflow: hidden; }
    .landing-page .video-js.side-bar-video video {
      object-fit: cover; }
    .landing-page .video-js.side-bar-video .vjs-poster {
      background-size: cover; }
  .landing-page .feature-icon-container .detail-text {
    min-height: 100px; }
    @media (max-width: 991px) {
      .landing-page .feature-icon-container .detail-text {
        min-height: 70px; } }
  .landing-page .screenshots .nav-tabs {
    margin-bottom: 0 !important;
    border: initial; }
    .landing-page .screenshots .nav-tabs .nav-link {
      cursor: pointer;
      border: initial;
      background: initial !important;
      padding-right: 40px;
      padding-left: 40px; }
    .landing-page .screenshots .nav-tabs .nav-item.show .nav-link:before,
    .landing-page .screenshots .nav-tabs .nav-link.active:before {
      top: initial;
      bottom: 0; }
  .landing-page .app-image {
    width: 100%;
    box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1);
    border-radius: 1.2rem; }
  .landing-page .doc-search {
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: transparent;
    border-radius: 40px;
    padding: 0.85rem 0.75rem 0.8rem;
    max-width: 360px; }
    .landing-page .doc-search input {
      color: #fff;
      background: transparent;
      width: 93%;
      padding: 0 0.75rem;
      outline: initial !important;
      border: initial; }
      .landing-page .doc-search input::placeholder {
        color: #fff;
        opacity: 0.7; }
    .landing-page .doc-search i {
      font-size: 16px;
      color: rgba(255, 255, 255, 0.7); }
  .landing-page .feedback-container button {
    font-size: 1.3em;
    color: #8f8f8f;
    margin: 0.5rem; }
    .landing-page .feedback-container button:hover, .landing-page .feedback-container button:active {
      color: #576a3d; }
  .landing-page .page-item .page-link {
    line-height: 1.2; }
  .landing-page .review-carousel {
    margin-top: 5rem; }
    .landing-page .review-carousel .react-siema-container > div {
      display: flex; }

/*Prices*/
.price-container .price-item .card {
  height: 100%; }

.price-container .price-item .card-body {
  text-align: center; }
  @media (max-width: 991px) {
    .price-container .price-item .card-body {
      text-align: left; } }
  @media (max-width: 575px) {
    .price-container .price-item .card-body {
      text-align: center; } }

.price-container .price-item .price-top-part {
  text-align: center; }
  @media (max-width: 991px) {
    .price-container .price-item .price-top-part {
      padding-left: 0;
      padding-right: 0;
      width: 40%; } }
  @media (max-width: 575px) {
    .price-container .price-item .price-top-part {
      width: initial; } }

.price-container .price-item .price-feature-list {
  justify-content: space-between; }
  @media (max-width: 991px) {
    .price-container .price-item .price-feature-list {
      min-height: 220px; } }
  .price-container .price-item .price-feature-list ul {
    margin: 0 auto;
    align-self: flex-start;
    margin-bottom: 1rem; }
    @media (max-width: 991px) {
      .price-container .price-item .price-feature-list ul {
        margin-left: 0; } }
    @media (max-width: 575px) {
      .price-container .price-item .price-feature-list ul {
        margin: 0 auto; } }
  @media (max-width: 991px) {
    .price-container .price-item .price-feature-list a {
      padding-left: 0; } }
  @media (max-width: 575px) {
    .price-container .price-item .price-feature-list a {
      padding-left: 2.6rem; } }

.feature-row {
  margin-top: 80px; }
  @media (max-width: 767px) {
    .feature-row {
      margin-top: 40px; } }

/* Timeline */
.timeline {
  list-style: none;
  padding: 10px 0;
  position: relative;
  font-weight: 300; }
  .timeline:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 2px;
    background: white;
    left: 50%;
    margin-left: -1.5px; }
    @media (max-width: 991px) {
      .timeline:before {
        left: 45px; } }
  .timeline > li {
    margin-bottom: 120px;
    position: relative;
    width: 50%;
    float: left;
    clear: left; }
    @media (max-width: 991px) {
      .timeline > li {
        width: 100%;
        margin-bottom: 30px; } }
  .timeline > li:before,
  .timeline > li:after {
    content: " ";
    display: table; }
  .timeline > li:after {
    clear: both; }
  .timeline > li:before,
  .timeline > li:after {
    content: " ";
    display: table; }
  .timeline > li:after {
    clear: both; }
  .timeline > li > .timeline-panel {
    width: calc(100% - 60px);
    float: left;
    position: relative; }
    @media (max-width: 991px) {
      .timeline > li > .timeline-panel {
        float: right;
        width: calc(100% - 120px);
        margin-right: 15px; } }
  .timeline > li > .timeline-panel:after {
    position: absolute;
    top: 16px;
    right: -7px;
    display: inline-block;
    border-top: 7px solid transparent;
    border-left: 7px solid white;
    border-right: 0 solid white;
    border-bottom: 7px solid transparent;
    content: " "; }
    @media (max-width: 991px) {
      .timeline > li > .timeline-panel:after {
        border-left-width: 0;
        border-right-width: 7px;
        left: -7px;
        right: auto; } }
  .timeline > li.timeline-inverted > .timeline-panel {
    float: right; }
  .timeline > li.timeline-inverted > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 7px;
    left: -7px;
    right: auto; }
  .timeline > li.timeline-inverted > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 7px;
    left: -7px;
    right: auto; }
  .timeline .timeline-badge > a {
    color: white !important; }
  .timeline > li.timeline-inverted {
    float: right;
    clear: right; }
  .timeline > li:nth-child(2) {
    margin-top: 120px; }
    @media (max-width: 991px) {
      .timeline > li:nth-child(2) {
        margin-top: 0; } }
  .timeline .no-float {
    float: none !important; }
  .timeline > li > .timeline-badge {
    color: white;
    width: 60px;
    height: 60px;
    font-size: 14px;
    text-align: center;
    position: absolute;
    background-color: #576a3d;
    right: -30px;
    border-radius: 30px;
    padding-top: 18px; }
    @media (max-width: 991px) {
      .timeline > li > .timeline-badge {
        left: 15px;
        right: initial; } }
  .timeline > li.timeline-inverted > .timeline-badge {
    right: initial;
    left: -30px; }
    @media (max-width: 991px) {
      .timeline > li.timeline-inverted > .timeline-badge {
        left: 15px; } }
