@import "./gogo/mixins";

$primary: var(--theme-color-1);
$white: var(--white);
$separator-color: var(--separator-color);

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
img {
  max-width: 100%;
}
.cropper-container {
  max-width: 100%;
}

.ems-metadata {
  input,
  textarea {
    border: none;
    resize: none !important;
    &:disabled {
      background: #ffffff;
    }
  }
  &-edited {
    input,
    textarea {
      border: 1px solid #d7d7d7;
    }
  }
}
.ems-title {
  @include respond-below(xs) {
    display: inline-block;
    font-size: 1rem;
    width: 100%;
    text-align: center;
    margin-bottom: 0;
    margin-top: -15px;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.menu-sub-hidden .h6 {
  z-index: 0;
}

.margeTitreTotalDoc {
  padding-left: 10px;
  margin-bottom: 20px;
}

.results {
  margin-top: 30px;
}
.results input {
  float: left;
  width: 186px;
  height: 40px;
  margin: 0 30px 30px 0;
}

.results input:last-child {
  white-space: nowrap;
}

.titreSetTotal {
  display: none;
}

.showTitreSetTotal {
  display: block;
  margin: 20px 0 0 17px;
}

.ems-users-griserArchive {
  color: #cecece !important;
}

.readMore {
  cursor: pointer;
  font-weight: bold;
}

#generator {
  width: 93%;
  height: 42px;
  background: #fff;
  padding: 8px 15px 8px 10px;
  border: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  border: 1px solid #c2c2c2;
  box-shadow: 1px 1px 4px #ebebeb;
  -moz-box-shadow: 1px 1px 4px #ebebeb;
  -webkit-box-shadow: 1px 1px 4px #ebebeb;
}

.msgHidden {
  display: none;
  color: red;
}

.ems-check-list-padding-left {
  padding-left: 1.4rem;
}

.ems-button-without-style {
  background-color: $white;
  color: inherit;
  border: none;
  padding: 0;
}

.ems-button-without-style:hover,
.ems-button-without-style:active {
  background-color: $white;
  color: $primary;
}

.ems-button-without-style.disabled,
.ems-button-without-style:disabled {
  background-color: $white;
  color: inherit;
}

.ems-check-all-download-row {
  padding-left: 23px;
  border-bottom: 1px solid $separator-color;
  margin-bottom: 3px;
}

.ems-summary-pt {
  padding-top: 11.2px;
}

.ems-show-more-link {
  width: fit-content;
  cursor: pointer;
}

.react-datepicker__input-container input {
  padding: inherit !important;
}

.ems-page-translation-text {
  white-space: pre;
}

.nav-tabs .nav-link,
.dropdown-menu > button.dropdown-item {
  cursor: pointer;
}

.react-datepicker-popper {
  z-index: 500;
}

.ems-audit-filter {
  z-index: 400;
}

.ems-page-navigation-dropdown {
  min-width: 80px;
}

.ems-video-player {
  max-width: 100%;
  margin: auto;
}

.custom-header {
  background-color: #eaeaea;
  box-shadow: none;
}  
